import { gql } from 'apollo-boost';
import { apolloClient } from '@/plugins/vue-apollo';
import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(leadIds) {
        const taskEventsQuery = apolloClient.query({
            query: gql`query taskEvents($leadIds: [Int]) {
                taskEvents(lead_ids: $leadIds) {
                    ...taskEventDetails
                }
            }

            fragment taskEventDetails on TaskEvent {
                id
                automation_action_id
                lead_id
                user_id
                created_by
                canceled
                completed_at
                confirmed
                description
                end_at
                main_event
                no_show
                start_at
                status
                task_event_type_id
                title

                automation_action {
                    automation_id
                }

                creator {
                    first_name
                    id
                    last_name
                }

                guests {
                    ...userDetails
                }

                lead {
                    ...leadDetails
                }

                user {
                    ...userDetails
                }
            }

            fragment userDetails on User {
                account {
                    id
                    name
                }
                account_id
                first_name
                id
                last_name
                profile_picture
            }

            fragment leadDetails on Lead {
                id
                account_id
                customer_id
                lead_type_id
                first_name
                last_name
                user_id
                commercial_id
                delivered_by_id
            }
            `,
            variables: {
                leadIds,
            },
        });

        return (await taskEventsQuery).data.taskEvents;
    },

    async show(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.get(`v1/task-events/${id}`, { params: payload });

        return response.data.data;
    },
};
