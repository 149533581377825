export default {
    account: {
        singular: 'space',
    },
    divisions: {
        general: 'Polestar',
    },
    civility: {
        singular: 'titre',
    },
    delivery: {
        singular: 'livraison',
        plural: 'livraisons',
    },
    deliverable: {
        singular: 'livrable',
    },
    delivered: {
        infinitive: 'livrer',
        singular: 'livré',
        plural: 'livrés',
        feminineSingular: 'livrée',
        femininePlural: 'livrées',
    },
    deliveryDate: {
        singular: 'à livrer le',
    },
    presented: {
        singular: 'space visit',
    },
    province: {
        CA: 'province / État',
    },
    renewal: {
        singular: 'rétention',
        plural: 'rétentions',
        prefix: 'la rétention',
        abr: 'réten.',
    },
    renewed: {
        infinitive: 'retenir',
        singular: 'retenu',
        plural: 'retenus',
    },
    result: {
        singular: 'contacté',
    },
    sale: {
        singular: 'commande',
        plural: 'commandes',
        prefix: 'une commande',
    },
    secondaryAdvisor: {
        singular: 'lead concierge',
    },
    sold: {
        singular: 'commandé',
        plural: 'commandés',
    },
    undelivered: {
        singular: 'non livré',
        plural: 'non livrés',
    },
    version: {
        singular: 'variante',
    },
    walkIn: {
        singular: 'space visit',
        plural: 'space visits',
        prefix: 'space visit',
        abr: 'space',
    },
    certified: 'Certifié',
};
