<template>
    <div
        class="z-40 fixed inset-y-0 flex flex-col bg-gray-750 shadow-r-lg overflow-x-hidden | transition-all duration-300"
        :class="navWidth"
        :style="{ paddingTop: `${bodySpacingTop}px` }"
        @mouseenter="!locked && !isTouchDevice && setNavExpanded(true)"
        @mouseleave="!locked && !isTouchDevice && setNavExpanded(false)"
        v-on-clickaway="onClickaway"
    >
        <div class="relative flex items-center justify-center shadow-lg h-18">
            <div class="changelog-selector | absolute top-0 right-0 mt-2.5" :class="expanded ? 'mr-1' : 'mr-2'" />

            <router-link class="w-full p-6" to="/">
                <div class="flex items-center w-52 px-3px"> <!-- Custom padding-x to center icon -->
                    <activix-crm-logo :icon-only="!expanded" />
                </div>
            </router-link>
        </div>

        <slot>
            <expandable-main-nav ref="nav" />
        </slot>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store.js';

    import ExpandableMainNav from '@/components/nav/ExpandableMainNav.vue';
    import ActivixCrmLogo from '@/components/ActivixCrmLogo.vue';
    import { isTouchDevice } from '@/utils/device.js';

    export default {
        components: {
            ActivixCrmLogo,
            ExpandableMainNav,
        },

        data: () => ({
            isTouchDevice: isTouchDevice(),
        }),

        computed: {
            ...mapState(useLayoutStore, {
                bodySpacingTop: 'bodySpacingTop',
                expanded: 'navExpanded',
                locked: 'navLocked',
            }),

            navWidth() {
                if (this.expanded) {
                    return 'w-64';
                }

                if (this.isTouchDevice) {
                    return 'w-0';
                }

                return 'w-20';
            },
        },

        watch: {
            '$route.name'() {
                this.onClickaway();
            },

            '$i18n.locale'(locale) {
                this.$beamer.changeLocale(locale);
            },
        },

        methods: {
            ...mapActions(useLayoutStore, ['setNavExpanded', 'setNavLocked']),

            onClickaway(event) {
                if (!isTouchDevice() || !this.expanded) {
                    return;
                }

                const sidebarToggle = document.querySelector('.sidebar-toggle');

                if (!event || (!event.target.classList.contains('sidebar-toggle') && !sidebarToggle.contains(event.target))) {
                    this.setNavExpanded(false);
                }
            },

            toggle() {
                this.setNavExpanded(!this.expanded);
            },

            toggleNavLock() {
                const nowLocked = !this.locked;

                this.setNavLocked(nowLocked);
                this.setNavExpanded(!nowLocked);
            },
        },

        created() {
            this.$eventBus.$on('toggle-nav', this.toggle);
            this.$eventBus.$on('toggle-nav-lock', this.toggleNavLock);
        },

        async mounted() {
            await this.$nextTick();

            this.$beamer.init(this.$auth.user());
        },

        beforeDestroy() {
            this.$eventBus.$off('toggle-nav', this.toggle);
            this.$eventBus.$off('toggle-nav-lock', this.toggleNavLock);
        },
    };
</script>
