import i18n from '@/plugins/vue-i18n.js';
import Enum from './Enum.js';

export default class Role extends Enum {
    static get entries() {
        return {
            admin: 1,
            manager: 2,
            programmer: 3,
            director: 4,
            advisor: 5,
            analyst: 6,
            agent: 7,
            director_bdc: 8,
            commercial: 9,
            service_agent: 10,
            service_advisor: 11,
        };
    }

    static get ADMIN() {
        return this.entries.admin;
    }

    static get MANAGER() {
        return this.entries.manager;
    }

    static get PROGRAMMER() {
        return this.entries.programmer;
    }

    static get DIRECTOR() {
        return this.entries.director;
    }

    static get ADVISOR() {
        return this.entries.advisor;
    }

    static get ANALYST() {
        return this.entries.analyst;
    }

    static get AGENT() {
        return this.entries.agent;
    }

    static get DIRECTOR_BDC() {
        return this.entries.director_bdc;
    }

    static get COMMERCIAL() {
        return this.entries.commercial;
    }

    static get SERVICE_AGENT() {
        return this.entries.service_agent;
    }

    static get SERVICE_ADVISOR() {
        return this.entries.service_advisor;
    }

    static get userRoles() {
        return [
            Role.DIRECTOR,
            Role.ADVISOR,
            Role.AGENT,
            Role.DIRECTOR_BDC,
            Role.COMMERCIAL,
            Role.SERVICE_AGENT,
            Role.SERVICE_ADVISOR,
        ];
    }

    static hasAdminRights(roleId) {
        return [Role.ADMIN, Role.MANAGER, Role.PROGRAMMER].includes(roleId);
    }

    static hasAdvisorSkills(roleId) {
        return [Role.ADVISOR, Role.AGENT, Role.COMMERCIAL].includes(roleId);
    }

    static hasDirectorRights(roleId) {
        return [Role.DIRECTOR, Role.DIRECTOR_BDC].includes(roleId);
    }

    static canBeEditedByBdcDirector(roleId) {
        return [Role.ADVISOR, Role.AGENT].includes(roleId);
    }

    static getTranslation(roleId) {
        return i18n.t(`database.role_${Role.getKey(roleId)}`);
    }
}
