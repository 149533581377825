export default {
    account: {
        singular: 'account',
    },
    divisions: {
        general: 'general',
    },
    civility: {
        singular: 'civility',
    },
    delivery: {
        singular: 'delivery',
        plural: 'deliveries',
    },
    deliverable: {
        singular: 'deliverable',
    },
    delivered: {
        infinitive: 'to deliver',
        singular: 'delivered',
        plural: 'delivered',
        feminineSingular: 'delivered',
        femininePlural: 'delivered',
    },
    deliveryDate: {
        singular: 'delivery date',
    },
    presented: {
        singular: 'visit',
    },
    province: {
        CA: 'province',
    },
    renewal: {
        singular: 'portfolio',
        plural: 'portfolios',
        prefix: 'a portfolio',
        abr: 'portfolio',
    },
    renewed: {
        infinitive: 'renew',
        singular: 'renewed',
        plural: 'renewed',
    },
    result: {
        singular: 'result',
    },
    sale: {
        singular: 'sale',
        plural: 'sales',
        prefix: 'a sale',
    },
    secondaryAdvisor: {
        singular: 'second associate',
    },
    sold: {
        singular: 'sold',
        plural: 'sold',
    },
    undelivered: {
        singular: 'undelivered',
        plural: 'undelivered',
    },
    version: {
        singular: 'version',
    },
    walkIn: {
        singular: 'walk-in',
        plural: 'walk-in',
        prefix: 'a walk-in',
        abr: 'walk-in',
    },
    certified: 'Certified',
};
