<template>
    <div
        class="w-full rounded bg-white"
        :class="{ 'border': isAdvanced }"
    >
        <div :class="{'w-full bg-gray-200': !isAdvanced}">
            <div :class="{'flex flex-col px-0 rounded bg-white border border-b-0 border-t-0': !isAdvanced}">
                <div class="px-6 pt-2 grid grid-cols-1 gap-6">
                    <div>
                        <label>{{ $t('responseLeadXpress.subject') }}</label>
                        <activix-input ref="subject" v-model="translation.subject" />
                    </div>
                    <div class="relative flex-1 flex flex-col space-y-6">
                        <template-editor
                            :locale="currentLang.toLowerCase()"
                            :lead="lead"
                            :mass-mailing="massMailing"
                            :design="translation.design || response.template.design"
                            @update-design="response.design = $event"
                            @update-body="response.body = $event"
                            v-model="translation.body"
                            v-if="isAdvanced"
                        />
                        <activix-summernote
                            :height="200"
                            :min-height="160"
                            :max-height="200"
                            :sticky-toolbar="!mdLayout"
                            :disabled="disallowCommunication"
                            :is-last="massMailing"
                            ref="summernote"
                            v-model="translation.body"
                            v-else
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';
    import TemplateEditor from '@/components/templates/TemplateEditor.vue';
    import TemplateType from '../../entities/TemplateType.js';
    import { insertAtCaret } from '../../utils/caret.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import Lead from '../../entities/Lead.js';

    export default {
        components: {
            TemplateEditor,
        },

        props: {
            templateType: {
                type: String,
                required: true,
            },
            translationData: {
                type: Object,
                required: true,
            },
            isAdvanced: {
                type: Boolean,
                default: true,
            },
            lead: {
                type: Lead,
                required: true,
            },
            massMailing: {
                type: Boolean,
                default: false,
            },
            communicating: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                translation: this.translationData,
                response: {},
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                templateAttributes: store => store.templateAttributes,
            }),

            subjectPreview() {
                let subject = 'N/A';
                if (this.translation.subject) {
                    subject = this.convertAttributesToContent(this.translation.subject, this.translation.locale);
                }

                return subject;
            },

            excerptPreview() {
                let excerpt = 'N/A';
                if (this.translation.excerpt) {
                    excerpt = this.convertAttributesToContent(this.translation.excerpt, this.translation.locale);
                }

                return excerpt;
            },

            bodyPreview() {
                let body = 'N/A';
                if (this.translation.body) {
                    body = this.convertAttributesToContent(this.translation.body, this.translation.locale);
                }

                return body;
            },

            creationDate() {
                return new ActivixDate(new Date()).toHumanShort(false);
            },

            creationTime() {
                return new ActivixDate(new Date()).toHumanTime();
            },

            disallowCommunication() {
                return this.communicating && !this.ownCommunication;
            },
        },

        watch: {
            translationData() {
                this.translation = this.translationData;
            },

            translation() {
                this.$emit('update', this.translation);
            },
        },

        methods: {
            fullScreen() {
                if (this.$refs.templateEditor) {
                    this.$refs.templateEditor.$el.requestFullscreen();
                }
            },

            convertAttributesToContent(content, locale) {
                content = this.normalizeTagText(content);

                this.templateAttributes.forEach(category => {
                    category.tags.forEach(tag => {
                        const placeholderContent = tag[`placeholder-${locale}`];
                        const attributeFr = this.normalizeTagText(`{${category.name_fr} - ${tag.text_fr}}`);
                        const attributeEn = this.normalizeTagText(`{${category.name_en} - ${tag.text_en}}`);

                        content = content.split(attributeFr).join(placeholderContent);
                        content = content.split(attributeEn).join(placeholderContent);
                    });
                });

                return content;
            },

            addTag(tag) {
                const element = document.activeElement;
                const summernoteParentElement = element.parentNode.parentNode.parentNode;
                const categoryName = this.getTagCategory(tag)['name_'.concat(this.$i18n.locale)];
                const text = this.normalizeTagText(`{${categoryName} - ${tag['text_'.concat(this.$i18n.locale)]}}`);

                if (!element) {
                    return;
                }

                // Subject
                if (this.$refs.subject.$el.isSameNode(element.parentNode)) {
                    insertAtCaret(element, text);
                }

                // Excerpt
                if (this.$refs.excerpt && this.$refs.excerpt.$el.isSameNode(element.parentNode)) {
                    insertAtCaret(element, text);
                }

                // Body
                if (this.$refs.summernote.$el.isSameNode(summernoteParentElement)) {
                    const node = document.createElement('span');
                    const textNode = document.createTextNode(text);

                    node.appendChild(textNode);

                    this.$refs.summernote.insertNode(node);
                }
            },

            getTagCategory(tag) {
                const category = this.templateAttributes.find(category => {
                    const tagInCategory = category.tags.some(t => t.tag == tag.tag);

                    return tagInCategory;
                });

                return category || {};
            },

            normalizeTagText(content) {
                if (content.includes('&amp;')) {
                    return content.replace(/&amp;/g, '&');
                }

                return content;
            },

            isEmail() {
                return this.templateType === TemplateType.EMAIL;
            },

            isNotification() {
                return this.templateType === TemplateType.NOTIFICATION;
            },
        },
    };
</script>

<style lang="less">
.notification-wrapper {
    @apply relative whitespace-nowrap border border-grey-200 p-4 flex items-center;

    width: 363px;

    .subject {
        @apply truncate text-sm text-grey-600;
    }
}
</style>
