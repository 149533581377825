<template>
    <div class="flex" :class="{ shadow: white }">
        <input
            class="form-control | flex-1"
            type="text"
            :class="[classes, { 'attached-r': showIcon }]"
            :disabled="disabled"
            :placeholder="placeholder"
            :required="required"
            :value="dateFormatted"
            ref="input"
            v-on="inputListeners"
        />
        <slot name="before-button" />
        <activix-button
            class="w-12 attached-l"
            :type="white ? 'white' : 'default'"
            :disabled="disabled"
            @click="showPicker"
            v-if="showIcon"
        >
            <icon name="regular/calendar-3" />
        </activix-button>
        <slot name="after-button" />
    </div>
</template>

<script>
    import Moment from '../../value-objects/Moment.js';

    export default {
        props: {
            dateOnly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            endDate: {
                type: String,
                default: '',
            },
            initialFocus: {
                type: Boolean,
                default: false,
            },
            inputClass: {
                type: [String, Array],
                default: '',
            },
            pickerPosition: {
                type: String,
                default: 'bottom-right',
            },
            placeholder: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false,
            },
            startDate: {
                type: String,
                default: '',
            },
            startView: {
                type: Number,
                default: 2,
            },
            value: {
                type: String,
                default: '',
            },
            white: {
                type: Boolean,
                default: false,
            },
            showIcon: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                date: '',
            };
        },

        computed: {
            classes() {
                const inputClass = Array.isArray(this.inputClass) ? this.inputClass : [this.inputClass];

                return inputClass.concat([{ 'input-white': this.white }]);
            },

            dateFunction() {
                return this.dateOnly ? this.localeDate : this.locale_dt;
            },

            displayFormat() {
                return Moment.humanShortLocalizedFormat(this.dateOnly);
            },

            dateFormatted() {
                return this.dateFunction(this.date).display(this.displayFormat);
            },

            endDateFormatted() {
                return this.dateFunction(this.endDate).display(this.displayFormat);
            },

            startDateFormatted() {
                return this.dateFunction(this.startDate).display(this.displayFormat);
            },

            pluginFormat() {
                if (this.dateOnly) {
                    return this.$i18n.locale === 'en' ? 'M dd, yyyy' : 'dd M yyyy';
                }

                return this.$i18n.locale === 'en' ? 'M dd, yyyy H:ii p' : 'dd M yyyy hh:ii';
            },

            inputListeners() {
                return {
                    ...this.$listeners,
                    ...{
                        input: () => {},
                        change: this.updateDate,
                    },
                };
            },
        },

        watch: {
            startDateFormatted() {
                if (this.startDateFormatted) {
                    $(this.$refs.input).datetimepicker('setStartDate', this.startDateFormatted);
                }
            },

            endDateFormatted() {
                if (this.endDateFormatted) {
                    $(this.$refs.input).datetimepicker('setEndDate', this.endDateFormatted);
                }
            },

            pluginFormat() {
                this.resetPicker();
            },

            value() {
                this.setDate();
            },
        },

        methods: {
            setDate() {
                this.date = this.dateFunction(this.value).toString();
            },

            initPicker() {
                this.setDate();

                $(this.$refs.input)
                    .datetimepicker({
                        minHour: 6,
                        autoclose: true,
                        endDate: this.endDateFormatted,
                        format: this.pluginFormat,
                        initialDate: this.dateFormatted,
                        language: this.$i18n.locale,
                        pickerPosition: this.pickerPosition,
                        startDate: this.startDateFormatted,
                        startView: this.startView,
                        todayBtn: true,
                    })
                    .on('changeDate', () => {
                        this.updateDate();
                    });

                if (this.initialFocus) {
                    $(this.$refs.input).focus();
                }
            },

            resetPicker() {
                $(this.$refs.input).datetimepicker('remove');
                this.initPicker();
            },

            updateDate() {
                const date = this.dateFunction(this.$refs.input.value, this.displayFormat);

                if (date.isValid()) {
                    this.date = date.toString();
                    this.$emit('input', this.date);

                    return;
                }

                this.$refs.input.value = '';
                this.date = '';
                this.$emit('input', '');
            },

            showPicker() {
                $(this.$refs.input).datetimepicker('show');
            },
        },

        mounted() {
            this.initPicker();
        },

        beforeDestroy() {
            $(this.$refs.input).datetimepicker('remove');
        },
    };
</script>

<style lang="less">
    .datetimepicker {
        .datetimepicker-hours {
            max-height: 270px;
            overflow: auto;
        }
    }
</style>
