import Caching from '@/plugins/caching.js';

class GraphCache {
    _memoryCache = {};

    memoryCache = {};

    static _instance = null;

    constructor() {
        const that = this;

        this.memoryCache = new Proxy(this._memoryCache, {
            get(target, p) {
                if (that._memoryCache[p]) {
                    return that._memoryCache[p];
                }

                return null;
            },
        });
    }

    static async partialUpdate(id, data) {
        const cachedItem = await GraphCache.get(id) || {};

        GraphCache.update(
            id,
            {
                ...cachedItem,
                ...data,
            },
        );
    }

    static update(id, data) {
        GraphCache.instance.memoryCache[id] = data;
    }

    static async get(id) {
        return GraphCache.instance.memoryCache[id];
    }

    static getCacheKey(id) {
        return `${Caching.GRAPHQL_OBJECT_CACHE}_${id}`;
    }

    static get instance() {
        if (!GraphCache._instance) {
            GraphCache._instance = new GraphCache();
        }

        return GraphCache._instance;
    }
}

export default GraphCache;
