<template>
    <div :class="{ 'z-50': showDropdown }" class="time-picker">
        <activix-popover
            placement="bottom-start"
            ref="timePickerPopover"
        >
            <template #trigger>
                <input
                    :class="{ 'cursor-not-allowed': disabled }"
                    :disabled="disabled"
                    class="display-time"
                    readonly
                    type="text"
                    v-model="displayTime"
                />
                <span
                    class="clear-btn"
                    @click.stop="clearTime"
                    v-if="!hideClearButton"
                    v-show="!showDropdown && showClearBtn"
                >
                    &times;
                </span>
            </template>
            <div class="dropdown z-30">
                <div class="select-list">
                    <ul class="hours" v-if="hourType">
                        <li class="hint sticky top-0">
                            H
                        </li>
                        <li
                            :class="{ active: hour === hr }"
                            :key="hr"
                            @click.stop="changeTimeByType('hour', hr)"
                            v-text="hr"
                            v-for="hr in hours"
                        />
                    </ul>
                    <ul class="minutes" v-if="minuteType">
                        <li class="hint sticky top-0">
                            M
                        </li>
                        <li
                            :class="{ active: minute === m }"
                            :key="m"
                            @click.stop="changeTimeByType('minute', m)"
                            v-text="m"
                            v-for="m in minutes"
                        />
                    </ul>
                    <ul class="seconds" v-if="secondType">
                        <li class="hint sticky top-0">
                            S
                        </li>

                        <li
                            :class="{ active: second === s }"
                            :key="s"
                            @click.stop="changeTimeByType('second', s)"
                            v-text="s"
                            v-for="s in seconds"
                        />
                    </ul>
                    <ul class="apms" v-if="apmType">
                        <li class="hint sticky top-0">
                            A
                        </li>
                        <li
                            :class="{ active: apm === a }"
                            :key="a"
                            @click.stop="changeTimeByType('apm', a)"
                            v-text="a"
                            v-for="a in apms"
                        />
                    </ul>
                </div>
            </div>
        </activix-popover>
    </div>
</template>

<script>

    import { ActivixPopover } from '@activix/ui';

    const defaultTimeValue = { hour: '', minute: '', second: '', apm: '' };

    const timeFormat = {
        hour: ['HH', 'H', 'hh', 'h', 'kk', 'k'],
        minute: ['mm', 'm'],
        second: ['ss', 's'],
        apm: ['A', 'a'],
    };

    const timeType = {
        hour: 'hour',
        minute: 'minute',
        second: 'second',
        apm: 'apm',
    };

    const standardTimeFormatKeys = ['HH', 'mm', 'ss'];

    function getValidInterval(timeType, interval) {
        if (interval === 0) {
            return 60;
        } if (interval > 60) {
            window.console.warn(`\`${timeType}-interval\` should be less than 60. Current value is`, interval);
            return 1;
        } if (interval < 1) {
            window.console.warn(
                `\`${timeType}-interval\` should be NO less than 1. Current value is`,
                interval,
            );
            return 1;
        } if (!interval) {
            return 1;
        }

        return interval;
    }

    export default {
        name: 'ActivixTimePicker',
        components: {
            ActivixPopover,
        },
        props: {
            format: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hideClearButton: {
                type: Boolean,
                default: false,
            },
            minuteInterval: {
                type: Number,
                default: 1,
            },
            secondInterval: {
                type: Number,
                default: 1,
            },
            value: {
                type: Object,
                default: () => undefined,
            },
        },

        data() {
            return {
                hours: [],
                minutes: [],
                seconds: [],
                apms: [],
                showDropdown: false,
                muteWatch: false,
                hourType: 'HH',
                minuteType: 'mm',
                secondType: '',
                apmType: '',
                hour: '',
                minute: '',
                second: '',
                apm: '',
                timeValues: null,
                baseFormatKeys: [],
            };
        },

        computed: {
            baseFormat() {
                if (this.format) {
                    return this.format;
                }

                return this.$i18n.locale === 'en' ? 'hh:mm A' : 'HH:mm';
            },
            displayTime() {
                if (!this.hasSelectedValue()) {
                    return this.baseFormat;
                }

                let formatString = this.baseFormat;

                if (this.hourType) {
                    formatString = formatString.replace(
                        this.hourType,
                        String(this.hour).padStart(2, '0'),
                    );
                }

                if (this.minuteType) {
                    formatString = formatString.replace(
                        this.minuteType,
                        String(this.minute).padStart(2, '0'),
                    );
                }

                if (this.secondType) {
                    formatString = formatString.replace(
                        this.secondType,
                        String(this.second).padStart(2, '0'),
                    );
                }

                if (this.apm && this.apmType) {
                    formatString = formatString.replace(new RegExp(this.apmType, 'g'), this.apm);
                }

                return formatString;
            },
            showClearBtn() {
                if ((this.hour && this.hour !== '') || (this.minute && this.minute !== '')) {
                    return true;
                }

                return false;
            },
        },

        watch: {
            format() {
                this.renderFormatAndList();
            },
            '$i18n.locale'() {
                this.renderFormatAndList();
            },
            minuteInterval(newInterval) {
                this.renderMinutesList(newInterval);
            },
            secondInterval(newInterval) {
                this.renderSecondsList(newInterval);
            },
        },

        methods: {
            checkAcceptingType(validValues, formatString, fallbackValue) {
                if (!validValues || !formatString || !formatString.length) {
                    return '';
                }

                for (let i = 0; i < validValues.length; i++) {
                    if (formatString.indexOf(validValues[i]) > -1) {
                        return validValues[i];
                    }
                }

                return fallbackValue || '';
            },
            clearTime() {
                if (this.disabled) {
                    return;
                }

                this.updateAllTimeValues(defaultTimeValue);
            },
            changeTimeByType(type, value) {
                let time = { hour: this.hour, minute: this.minute, second: this.second, apm: this.apm };

                if (type === timeType.hour) {
                    time.hour = value;
                } else if (type === timeType.minute) {
                    time.minute = value;
                } else if (type === timeType.second) {
                    time.second = value;
                } else if (type === timeType.apm) {
                    time.apm = value;
                }

                time = { ...this.setDefaultTimeValue(time) };

                this.updateAllTimeValues(time);
            },
            hasSelectedValue() {
                return (this.hour && this.hourType) || (this.minute && this.minuteType) || (this.second && this.secondType) || (this.apm && this.apmType);
            },
            getDefaultHour() {
                const isTwelveHours = this.isTwelveHours(this.hourType);

                let hour = 0;
                if (isTwelveHours) {
                    hour = 12;
                    if (this.hourType === 'hh' || this.hourType === 'kk') {
                        hour = 11;
                    }
                }

                return this.formatValue(this.hourType, hour);
            },
            getHoursAndAmpValues(baseHour, baseApm) {
                const isTwelveHours = this.isTwelveHours(this.hourType);

                const apmValue = isTwelveHours && baseApm ? String(baseApm).toLowerCase() : false;
                const hourValue = baseHour || baseHour === 0 ? Number(baseHour) : '';

                const hoursAndAmpValues = {};

                timeFormat.hour.forEach(hourFormat => {
                    if (!String(hourValue).length) {
                        hoursAndAmpValues[hourFormat] = '';
                        hoursAndAmpValues.a = '';
                        hoursAndAmpValues.A = '';
                        return;
                    }

                    let hour;
                    let apm;

                    switch (hourFormat) {
                        case 'H':
                        case 'HH':
                            hour = hourValue;
                            if (apmValue) {
                                if (apmValue === 'pm' && hour < 12) {
                                    hour += 12;
                                } else if (apmValue === 'am' && hour > 12) {
                                    hour %= 12;
                                }
                            }

                            hoursAndAmpValues[hourFormat] = hourFormat === 'HH' && hour < 10 ? `0${hour}` : String(hour);
                            break;

                        case 'k':
                        case 'kk':
                            hour = hourValue === 0 ? 24 : hourValue;

                            if (isTwelveHours) {
                                if (apmValue === 'pm') {
                                    hour = hourValue < 12 ? hourValue + 12 : hourValue;
                                } else {
                                    hour = hourValue === 12 ? 24 : hourValue;
                                }
                            }

                            hoursAndAmpValues[hourFormat] = hourFormat === 'kk' && hour < 10 ? `0${hour}` : String(hour);
                            break;

                        case 'h':
                        case 'hh':
                            if (apmValue) {
                                hour = hourValue;
                                apm = apmValue || 'am';
                                if (hour > 12) {
                                    hour %= 12;
                                }
                            } else if (hourValue > 11) {
                                apm = 'pm';
                                hour = hourValue === 12 ? 12 : hourValue % 12;
                            } else {
                                apm = 'am';
                                hour = hourValue % 12 === 0 ? 12 : hourValue;
                            }

                            hoursAndAmpValues[hourFormat] = hourFormat === 'hh' && hour < 10 ? `0${hour}` : String(hour);
                            hoursAndAmpValues.a = apm;
                            hoursAndAmpValues.A = apm.toUpperCase();

                            break;
                    }
                });

                return hoursAndAmpValues;
            },
            formatValue(format, i) {
                switch (format) {
                    case 'H':
                    case 'm':
                    case 's':
                        return String(i);

                    case 'HH':
                    case 'mm':
                    case 'ss':
                        return i < 10 ? `0${i}` : String(i);

                    case 'h':
                    case 'k':
                        return String(i + 1);

                    case 'hh':
                    case 'kk':
                        return i + 1 < 10 ? `0${i + 1}` : String(i + 1);

                    default:
                        return '';
                }
            },
            isTwelveHours(hourFormat) {
                return hourFormat === 'h' || hourFormat === 'hh';
            },
            readValue(values) {
                if (!values || this.muteWatch) {
                    return;
                }

                const timeValue = JSON.parse(JSON.stringify(values || {}));
                const formatValues = Object.keys(timeValue);

                if (formatValues.length === 0) {
                    return;
                }

                const hour = this.readTimeValue(timeValue, timeFormat.hour, formatValues, this.hour);
                const minute = this.readTimeValue(timeValue, timeFormat.minute, formatValues, this.minute);
                const second = this.readTimeValue(timeValue, timeFormat.second, formatValues, this.second);
                let apm = this.apm;

                if (timeFormat.apm.some(timeFormat => formatValues.includes(timeFormat))) {
                    const apmFormat = formatValues.find(format => timeFormat.apm.includes(format));
                    if (apmFormat) {
                        apm = timeValue[apmFormat];
                    }
                }

                this.updateAllTimeValues({ hour, minute, second, apm });
            },
            readTimeValue(timeValue, timeFormatType, formatValues, defaultValue) {
                if (timeFormatType.some(timeFormat => formatValues.includes(timeFormat))) {
                    const timeFormat = formatValues.find(format => timeFormatType.includes(format));
                    if (timeFormat) {
                        return this.formatValue(timeFormat, Number(timeValue[timeFormat]));
                    }
                }

                return defaultValue;
            },
            renderFormatAndList() {
                this.hourType = this.checkAcceptingType(timeFormat.hour, this.baseFormat, 'HH');
                this.minuteType = this.checkAcceptingType(timeFormat.minute, this.baseFormat, 'mm');
                this.secondType = this.checkAcceptingType(timeFormat.second, this.baseFormat);
                this.apmType = this.checkAcceptingType(timeFormat.apm, this.baseFormat);

                this.baseFormatKeys = [];

                if (this.hourType) {
                    this.renderHoursList();
                    this.baseFormatKeys.push(this.hourType);
                }

                if (this.minuteType) {
                    this.renderMinutesList();
                    this.baseFormatKeys.push(this.minuteType);
                }

                if (this.secondType) {
                    this.renderSecondsList();
                    this.baseFormatKeys.push(this.secondType);
                }

                if (this.apmType) {
                    this.renderApmList();
                    this.baseFormatKeys.push(this.apmType);
                }

                this.$nextTick(() => {
                    this.readValue(this.timeValues ?? this.value);
                });
            },
            renderHoursList() {
                const hoursCount = this.hourType === 'h' || this.hourType === 'hh' ? 12 : 24;
                this.hours = [];

                for (let i = 0; i < hoursCount; i++) {
                    this.hours.push(this.formatValue(this.hourType, i));
                }
            },
            renderSecondsList(interval) {
                const validInterval = getValidInterval(timeType.second, interval ?? this.secondInterval);
                this.seconds = [];

                for (let i = 0; i < 60; i += validInterval) {
                    this.seconds.push(this.formatValue(this.secondType, i));
                }
            },
            renderMinutesList(interval) {
                const validInterval = getValidInterval(timeType.minute, interval ?? this.minuteInterval);
                this.minutes = [];

                for (let i = 0; i < 60; i += validInterval) {
                    this.minutes.push(this.formatValue(this.minuteType, i));
                }
            },
            renderApmList() {
                this.apms = [];

                if (!this.apmType) {
                    return;
                }

                this.apms = this.apmType === 'A' ? ['AM', 'PM'] : ['am', 'pm'];
            },
            setDefaultTimeValue(time) {
                const defaultTime = { ...time };
                if (!defaultTime.hour && this.hourType) {
                    defaultTime.hour = this.getDefaultHour();
                }

                if (!defaultTime.minute && this.minuteType) {
                    defaultTime.minute = this.formatValue(this.minuteType, 0);
                }

                if (!defaultTime.second && this.secondType) {
                    defaultTime.second = this.formatValue(this.secondType, 0);
                }

                if (!defaultTime.apm && this.apmType && !!this.apms.length) {
                    defaultTime.apm = this.apms[0];
                }

                return defaultTime;
            },
            setTimeValuesToData(timeValues) {
                this.baseFormatKeys.forEach(key => {
                    switch (key) {
                        case 'H':
                        case 'h':
                        case 'k':
                        case 'kk':
                        case 'HH':
                        case 'hh':
                            this.hour = timeValues[key];
                            break;

                        case 'm':
                        case 'mm':
                            this.minute = timeValues[key];
                            break;

                        case 's':
                        case 'ss':
                            this.second = timeValues[key];
                            break;

                        case 'a':
                        case 'A':
                            this.apm = timeValues[key];
                            break;
                    }
                });
            },
            updateAllTimeValues(timeValue) {
                const { hour, minute, second, apm } = timeValue;

                const allValues = {
                    ...this.getHoursAndAmpValues(hour, apm),
                    m: '',
                    mm: '',
                    s: '',
                    ss: '',
                };

                if (minute || minute === 0) {
                    const minuteValue = Number(minute);
                    allValues.m = String(minuteValue);
                    allValues.mm = minuteValue < 10 ? `0${minuteValue}` : String(minuteValue);
                }

                if (second || second === 0) {
                    const secondValue = Number(second);
                    allValues.s = String(secondValue);
                    allValues.ss = secondValue < 10 ? `0${secondValue}` : String(secondValue);
                }

                this.setTimeValuesToData(allValues);

                this.updateTimeValue(allValues);

                this.$emit('change', { data: allValues });
            },
            updateTimeValue(allValues) {
                const timeValues = {};

                standardTimeFormatKeys.forEach(key => {
                    timeValues[key] = allValues[key];
                });

                this.timeValues = { ...timeValues };

                this.$emit('input', timeValues);
            },
        },

        mounted() {
            this.renderFormatAndList();

            this.$watch(
                () => {
                    return this.$refs.timePickerPopover.$refs.popper.show;
                },
                (show) => {
                    if (!show) {
                        return;
                    }

                    this.$refs.timePickerPopover.$el.querySelector('.hours .active')?.scrollIntoView({ block: 'center' });
                    this.$refs.timePickerPopover.$el.querySelector('.minutes .active')?.scrollIntoView({ block: 'center' });
                    this.$refs.timePickerPopover.$el.querySelector('.seconds .active')?.scrollIntoView({ block: 'center' });
                    this.$refs.timePickerPopover.$el.querySelector('.apms .active')?.scrollIntoView({ block: 'center' });
                },
            );
        },
    };
</script>
