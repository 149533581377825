import Vue from 'vue';
import tippy from 'tippy.js';
import * as Sentry from '@sentry/vue';

Vue.use({
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        const tooltip = {
            init(element, options) {
                if (typeof options === 'string') {
                    options = { content: options };
                }

                if (element instanceof jQuery) {
                    element = element[0];
                }

                const existingInstance = this.getInstance(element);

                if (existingInstance) {
                    existingInstance.setProps(options);

                    return existingInstance;
                }

                try {
                    return tippy(element, {
                        animation: 'fade',
                        allowHTML: true,
                        offset: [0, 5],
                        ...options,
                    });
                } catch (e) {
                    Sentry.captureMessage('Error on Tippy', {
                        level: 'error',
                        extra: { options },
                    });

                    throw e;
                }
            },

            setContent(element, content) {
                const instance = this.getInstance(element);

                if (!instance) {
                    return this.init(element, content);
                }

                if (!content) {
                    instance.destroy();
                } else {
                    instance.setContent(content);
                }

                return instance;
            },

            destroy(element) {
                const instance = this.getInstance(element);

                if (instance) {
                    instance.destroy();
                }
            },

            getInstance(element) {
                if (element instanceof jQuery) {
                    element = element[0];
                }

                return element._tippy;
            },
        };

        VueInstance.prototype.$tooltip = tooltip;
        VueInstance.tooltip = tooltip;
    },
});
