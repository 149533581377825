export default {
    alerts: {
        stats: {
            error: 'Failed retrieving the statistics',
        },
        leads: {
            error: 'Failed retrieving the leads',
        },
        graphs: {
            error: 'Failed retrieving the graphics',
        },
    },

    clickHereToGroupByClientSource: 'Click here to group by client source',
    clickHereToGroupByApprovedSource: 'Click here to group by approved source',
    filterPlaceholder: 'Filter...',
    activityReport: 'Activity Report',
    allLead: 'My clients',
    commercial: 'F&I',
    event: 'Event',
    leadXpress: 'Web Lead',
    loyalty: 'Loyalty',
    phoneUp: 'Phone-up',
    renewal: '@.capitalize:lexicon.renewal.singular',
    saleTable: '@.capitalize:lexicon.sale.plural Board',
    service: 'Service',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    webBoost: 'WebBoost',
    webOrder: 'Web Order',

    phoneUpBy: 'Phone-up by',
    phoneUpByAccount: 'Phone-up by',
    clientBy: 'Clients by',
    clientByAccount: 'Clients by',
    leadBy: 'Leads by',
    leadByAccount: 'Leads by',
    walkInBy: '@.capitalize:lexicon.walkIn.singular by',
    walkInByAccount: '@.capitalize:lexicon.walkIn.singular by',
    webOrderBy: 'Orders by',
    webOrderByAccount: 'Orders by',

    leadsSelectedOnThisPage: '{count} lead selected on this page. | {count} leads selected on this page.',
    allLeadsSelected: 'All {count} leads are selected.',
    allLeadsSelectedWithBlackList: '{count} leads are selected.',
    selectAllLeads: 'Select all {count} leads.',
    unselectAllLeads: 'Clear selection.',
    disabledForGroups: 'Module not available for groups.',
    moduleNotEnabledForAccount: 'Module not enabled for this account.',
    noResults: 'No results',
    tooManyResults: 'Over 10,000 results. Please reduce your date range to see the leads.',
    tooManySalesResults: 'Over 1,000 results. Please reduce your date range to see the @:lexicon.sale.plural .',
    leadSelected: 'lead selected | leads selected',
    tooMuchLeadWarning:
        'Displaying 100 leads or more can slow down the data loading process. @:general.wishToProceed',
    enterValidPhone: 'Enter a valid number or leave the field empty',
    addContactInfo: 'Add contact info',
    overwrite: 'Update',
    dateMultiselectTooltip:
        'Choose the fields that are filtered by your date range. Each field is filtered independently.',
    infoDashboardView: 'Save your filters and current configurations for this dashboard',
    saveDashboardViewTooltip: 'Save a custom view',
    addDashboardView: 'Add a view',
    editDashboardView: 'Edit this view',
    deleteDashboardView: 'Delete this view',
    shareDashboardView: 'Share this view',
    displayDashboardView: 'View information',
    viewSaveDate: 'Save current dates',
    viewSaveDateTooltip: 'Save current dates, ideal to return to a specific month quickly',
    addEventLeads: 'Add an event',
    eventBeingCreated: 'Event creation in progress...',
    eventAddingLeads: 'Adding leads to {event} event...',
    massAction: 'Other actions',
    infoAddEventLeads: 'Add to an event',
    apptByPhone: 'Appointment by phone',
    vehicleFieldRequiresModelOrYear: 'The vehicle must include a model or year',
    addOrModifyAppointment: 'Modifying this phone appointment is irreversible and may affect your stats.',
    infoSubscription: 'Subscribe to receive a report of this view by email',
    save: 'Save',
    editSubscription: 'Edit subscription',
    addSubscription: 'Add subscription',
    displaySubscription: 'Subscription information',
    renewalAbr: '@.capitalize:lexicon.renewal.abr',
    renewalBdcAggregation: 'BDC Agent statistics consolidation, excluded from totals',
    prebooking: 'Pre-book.',
    sms: 'SMS',
    newEvent: 'New event',
    eventName: 'Event name',
    segment: 'Segment',
    webLead: 'Web Lead',
    dateFilterTooltip: 'This filter is only based on the day, the month and the year (i.e. "21 Oct 2018")',
    dateFilterWithoutYearTooltip: 'This filter is only based on the day and the month (i.e. "21 Oct")',
    importFileTooltip: 'Name of the file from which the lead was imported',
    applyFilter: 'Apply',
    clearFilter: 'Clear',
    createdByUser: 'Lead added manually by a user',
    createdBy: 'Created by',
    updatedBy: 'Updated by',
    filter: 'Filter',
    toggleColumns: 'Column visibility',
    importedCampaign: 'Campaign',
    inDate: 'Result within the date range',
    notInDate: 'Result outside the date range',
    dateNotInRange: 'This date is outside your date range',
    downloadScanXpress: 'Download Scan Xpress',
    profitAndAverage: 'Profits and averages',
    noData: 'No data available in table',
    refresh: 'Refresh',
    deleteViewConfirmation: 'Your custom view will be deleted permanently (this will not affect your leads)',
    deleteSharedViewConfirmation: 'This view will be removed from your shared view list (this will not affect your leads)',
    viewTitle: 'Title',
    disabledSavedDateTooltip: 'This option is disabled when the dates selected are relative to the current date',
    showElement: 'Showing element',
    to: 'to',
    of: 'of',
    show: 'Show',
    elements: 'elements',
    starGray: 'Manually added',
    starBlue: 'Scan Xpress',
    starGreen: 'Imported from DMS',
    starOrange: 'Imported manually',
    starBabyBlue: 'Automatic @:lexicon.renewal.singular',
    noAdvisor: 'No Associate',
    noAgent: 'No Agent',
    dms: 'DMS',
    noAudioAccess: 'You do not have access to this audio',
    noShow: 'No show',
    canceled: 'Canceled',
    callAppt: 'Call Appt.',
    noTimeAppt: 'No time',
    removeIt: 'Yes, delete it!',
    deleteFollowUpConfirmation:
        'Deleting this {0} is irreversible. Keep in mind that you can edit the date from the client card.',
    noCampaignFound: 'No campaign found',
    bdcAgentLabel: 'BDC Agent',
    alertDate: 'Alert date',
    alertTypes: {
        columnName: 'Alert types',
        equity: 'Equity',
        location: 'End of Lease',
        warranty: 'End Warranty',
        warranty_icon: 'W',
        equityWithPartialMatch: 'Equity. The vehicle needs to be validated to get the exact value.',
    },
    customFields: {
        unknownVehicle: 'Unknown Vehicle',
        vehicles: 'vehicles',
    },
    performance: {
        appointment: 'appointment',
        appointmentPhone: 'phone appointment',
        appt_call: 'phone appointment date',
        appt_confirmation: 'appointment confirmation',
        home_presented: 'home visit',
        presented: 'visit',
        home_road_test: 'home test drive',
        road_test: 'test drive',
        sale: '@:lexicon.sale.singular',
        sale_by_phone: '@:lexicon.sale.singular by phone',
        refinanced: 'Buy Out',
        available: "vehicle's scheduled arrival date",
        deliverable: 'date where the vehicle will be @:lexicon.deliverable.singular',
        delivery: 'the scheduled @:lexicon.delivery.singular date',
        delivered: '@:lexicon.delivery.singular',
        recorded: 'reported date',
        paperwork: 'paperwork',
    },
    deleteUpcomingTask: {
        description: 'Do you want to delete future tasks and appointments for this lead?',
        delete: 'Yes, delete them',
        keep: 'No, keep them',
    },
    areYouSure: 'Are you sure?',
    process: {
        paperwork: 'Paperwork',
    },

    leadState: {
        toCheck: 'To validate',
        merged: 'Merged',
        associated: 'Associated',
        bothStates: 'Merged and associated',
        noState: '-',
        none: 'None',
    },
    createdMethodString: 'Method',
    leadStateString: 'Association State',
    assignedClient: 'Assigned client | Assigned clients',
    assignedLead: 'Assigned lead | Assigned leads',
    assignedWalkIn: 'Assigned @.capitalize:lexicon.walkIn.singular | Assigned @.capitalize:lexicon.walkIn.plural',
    assignedWebOrder: 'Assigned order | Assigned orders',

    wanted: 'want.',
    exchange: 'exch.',

    statsTableLoading: 'Calculation in progress...',

    segments: {
        other: 'Other',
        notSold: 'Not @:lexicon.sold.singular',
        service: 'Service',
        loyalty: 'Loyalty',
        promo: 'Promotion',
        conquest: 'Conquest',
        reminder: 'Reminder',
        endWarranty: 'End warranty',
        endLcap: 'End LCAP',
        endLnette: 'End LNETTE',
        nowShow: 'No show',
        csi: 'CSI',
    },

    stats: {
        headers: {
            premium: 'Premium',
            standard: 'Standard',
            activities: 'Communications',
            averages: 'Average',
            leads: 'Leads',
            performances: 'Performances',
            profit: 'Profit & average',
            process: 'Process',
            renewalLoyalty: '@.capitalize:lexicon.renewal.singular & Loyalty',
            results: 'Results',
            sales: '@.capitalize:lexicon.sale.plural',
            salesAndDeliveries: '@:lexicon.sale.plural & @.capitalize:lexicon.delivery.plural',
            soldServices: '@.capitalize:lexicon.sold.singular Services',
            total: 'Total',
            walkIn: '@.capitalize:lexicon.walkIn.singular',
            walkInAndPhoneUp: '@.capitalize:lexicon.walkIn.singular & Phone-up',
            phoneUp: 'Phone-up',
            visits: 'Visits',
            web: 'Web',
            withAppointments: 'With appt.',
            withoutAppointments: 'Without appt.',
        },
    },

    ringcentral: {
        listen: 'Listen',
        listenInRingcentral: 'Listen in RingCentral',
    },
};
