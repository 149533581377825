import AccountType from '@/entities/AccountType.js';
import Enum from './Enum.js';
import i18n from '../plugins/vue-i18n.js';

export default class ClientCardSection extends Enum {
    static get entries() {
        return {
            generalInfo: 'generalInfo',
            performance: 'performance',
            vehicleWantedInfo: 'vehicleWantedInfo',
            vehicleWantedPriceAndContract: 'vehicleWantedPriceAndContract',
            vehicleExchangeInfo: 'vehicleExchangeInfo',
            vehicleExchangePriceAndContract: 'vehicleExchangePriceAndContract',
            vehicleExchangeAppraisal: 'vehicleExchangeAppraisal',
            process: 'process',
            processService: 'processService',
            funnel: 'funnel',
            dollarAndLost: 'dollarAndLost',
        };
    }

    static get GENERAL_INFO() {
        return this.entries.generalInfo;
    }

    static get PERFORMANCE() {
        return this.entries.performance;
    }

    static get VEHICLE_WANTED_INFO() {
        return this.entries.vehicleWantedInfo;
    }

    static get VEHICLE_WANTED_PRICE_AND_CONTRACT() {
        return this.entries.vehicleWantedPriceAndContract;
    }

    static get VEHICLE_EXCHANGE_INFO() {
        return this.entries.vehicleExchangeInfo;
    }

    static get VEHICLE_EXCHANGE_PRICE_AND_CONTRACT() {
        return this.entries.vehicleExchangePriceAndContract;
    }

    static get VEHICLE_EXCHANGE_APPRAISAL() {
        return this.entries.vehicleExchangeAppraisal;
    }

    static get PROCESS() {
        return this.entries.process;
    }

    static get PROCESS_SERVICE() {
        return this.entries.processService;
    }

    static get FUNNEL() {
        return this.entries.funnel;
    }

    static get DOLLAR_AND_LOST() {
        return this.entries.dollarAndLost;
    }

    static get translationGroup() {
        return 'clientCard.customFieldSections';
    }

    static get leadGroup() {
        return 'lead';
    }

    static get vehicleExchangeGroup() {
        return 'vehicleExchange';
    }

    static get vehicleWantedGroup() {
        return 'vehicleWanted';
    }

    static get leadSections() {
        return [
            this.GENERAL_INFO,
            this.PERFORMANCE,
            this.PROCESS,
            this.PROCESS_SERVICE,
            this.FUNNEL,
            this.DOLLAR_AND_LOST,
        ];
    }

    static get vehicleExchangeSections() {
        return [
            this.VEHICLE_EXCHANGE_INFO,
            this.VEHICLE_EXCHANGE_PRICE_AND_CONTRACT,
            this.VEHICLE_EXCHANGE_APPRAISAL,
        ];
    }

    static get vehicleWantedSections() {
        return [
            this.VEHICLE_WANTED_INFO,
            this.VEHICLE_WANTED_PRICE_AND_CONTRACT,
        ];
    }

    static getSectionGroup(section) {
        if (this.isVehicleExchangeField(section)) {
            return this.vehicleExchangeGroup;
        }

        if (this.isVehicleWantedField(section)) {
            return this.vehicleWantedGroup;
        }

        return this.leadGroup;
    }

    static isLeadField(section) {
        return this.leadSections.includes(section);
    }

    static isVehicleField(section) {
        return this.isVehicleExchangeField(section) ||
            this.isVehicleWantedField(section);
    }

    static isVehicleExchangeField(section) {
        return this.vehicleExchangeSections.includes(section);
    }

    static isVehicleWantedField(section) {
        return this.vehicleWantedSections.includes(section);
    }

    static selectOptionsWithGroups(account, idName = '', labelName = '') {
        const accountIsCustom = account.type === AccountType.CUSTOM;

        return [
            {
                label: i18n.t('clientCard.customFieldSections.group.lead'),
                group: this.leadGroup,
                options: this.leadSections
                    .filter(section => accountIsCustom || ![this.FUNNEL, this.DOLLAR_AND_LOST].includes(section))
                    .map(section => this.getSelectOption(section, idName, labelName)),
            },
            {
                label: i18n.t('clientCard.customFieldSections.group.vehicleExchange'),
                group: this.vehicleExchangeGroup,
                options: this.vehicleExchangeSections
                    .map(section => this.getSelectOption(section, idName, labelName)),
            },
            {
                label: i18n.t('clientCard.customFieldSections.group.vehicleWanted'),
                group: this.vehicleWantedGroup,
                options: this.vehicleWantedSections
                    .map(section => this.getSelectOption(section, idName, labelName)),
            },
        ];
    }
}
