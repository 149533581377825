import { gql } from 'apollo-boost';
import { forEach, get, lowerFirst, mergeWith, uniqueId } from 'lodash-es';
import { apolloClient } from '@/plugins/vue-apollo.js';
import AsyncDebounce from '@/utils/AsyncDebounce.js';

class GraphQuerier {
    static fetchList = {};

    static debounceTimer = 500;

    static async fetchItemWithFields(modelClass, fields, identifiers) {
        let identifier = GraphQuerier.getCacheKeyForObject({
            __typename: modelClass,
            ...identifiers,
        });

        if (!identifier) {
            throw new Error('Missing identifier to perform the GraphQL query');
        }

        identifier = identifier.replaceAll(':', '___');

        GraphQuerier.fetchList[`${identifier}`] = {
            type: modelClass,
            identifiers,
            changes: [
                ...new Set([
                    ...(get(GraphQuerier.fetchList, `${identifier}.changes`, ['id'])),
                    ...Object.values(fields),
                ]),
            ],
        };

        const response = await AsyncDebounce.debounce('GraphQuerier', GraphQuerier.execute, GraphQuerier.debounceTimer, GraphQuerier.debounceTimer * 2);

        return response.data[identifier];
    }

    static async execute() {
        let queryBody = '';

        const variables = {};

        const fetchList = GraphQuerier.fetchList;
        GraphQuerier.fetchList = {};

        if (Object.keys(fetchList).length) {
            forEach(fetchList, (fetch, fetchId) => {
                queryBody += GraphQuerier.generateQueryForItem(fetch, variables, fetchId);
            });

            const queryHead = GraphQuerier.generateMainQueryHeader(variables);
            const query = `${queryHead} { ${queryBody} }`;

            return GraphQuerier.queryToGraphQL(query, variables);
        }

        return {};
    }

    static generateMainQueryHeader(variables) {
        let query = 'query';

        if (variables) {
            const variableStr = Object.entries(variables)
                .map(([key, value]) => `$${key}: ${value.type}`)
                .join(', ');

            query += `(${variableStr})`;
        }

        return query;
    }

    static generateQueryForItem(item, variables, fetchId) {
        const queryHead = GraphQuerier.generateQueryHeadForItem(item, variables, fetchId);
        const queryBody = GraphQuerier.generateQueryBodyForItem(item);

        return `${queryHead} { ${queryBody} }`;
    }

    static generateQueryHeadForItem(item, variables, fetchId) {
        let queryHead = `${fetchId}: ${lowerFirst(item.type)}`;
        const queryVariables = [];

        forEach(item.identifiers, (identifier, identifierKey) => {
            if (identifierKey.startsWith('_') || !identifier) {
                return;
            }

            const idFetchVar = uniqueId('graphql_var_');
            queryVariables.push(`${identifierKey}: $${idFetchVar}`);

            if (typeof identifier === 'object') {
                variables[idFetchVar] = identifier;
            } else {
                variables[idFetchVar] = {
                    type: 'Int',
                    value: identifier,
                };
            }
        });

        if (queryVariables.length) {
            queryHead += `(${queryVariables.join(',')})`;
        }

        return queryHead;
    }

    static generateQueryBodyForItem(item) {
        const fieldsInObjectNotation = GraphQuerier.mapFieldsFromDotToObjectNotation(item.changes);

        return GraphQuerier.objectNotationToQuery(fieldsInObjectNotation);
    }

    static mapFieldsFromDotToObjectNotation(fields) {
        return fields.reduce((mappedFields, fieldPath) => {
            return mergeWith(mappedFields, GraphQuerier.dotToObjectNotation(fieldPath), (dest, src) => {
                return Array.isArray(dest) ? dest.concat(src) : undefined;
            });
        }, {});
    }

    static dotToObjectNotation(fieldPath) {
        if (!fieldPath.includes('.')) {
            return {
                fields: [fieldPath],
            };
        }

        const fieldParts = fieldPath.split('.');
        const relationKey = fieldParts.shift();
        const field = fieldParts.join('.');

        return {
            relations: {
                [relationKey]: GraphQuerier.dotToObjectNotation(field),
            },
        };
    }

    static objectNotationToQuery(object) {
        const fields = (object.fields || []).join(' ');

        if (!object.relations) {
            return fields;
        }

        const relations = Object.entries(object.relations).map(([relationName, relationData]) => {
            return `${relationName} { ${GraphQuerier.objectNotationToQuery(relationData)} }`;
        });

        return `${fields} ${relations.join(' ')}`;
    }

    static async queryToGraphQL(query, variables) {
        const graphQlVariables = {};

        forEach(variables, (value, key) => {
            graphQlVariables[key] = parseInt(value.value, 10);
        });

        return apolloClient.query({
            query: gql(query),
            variables: graphQlVariables,
        });
    }

    static getCacheKeyForObject(object) {
        switch (object.__typename) {
            case 'AccountProduct':
            case 'AccountSupplier':
                if (!object.id || !object.account_id) {
                    return null;
                }

                return `${object.__typename}:${object.id}_${object.account_id}`;
            case 'FlowUser':
                if (!object.id || !object.flow_id) {
                    return null;
                }

                return `${object.__typename}:${object.id}_${object.flow_id}`;
            default:
                if (!object.id && !object._id) {
                    return null;
                }

                return `${object.__typename}:${object.id || object._id}`; // fall back to `id` and `_id` for all other types
        }
    }
}

export default GraphQuerier;
