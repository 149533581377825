<template>
    <div class="flex-1">
        <template v-if="editing">
            <comment-form
                class="ml-2"
                :adding="false"
                :comment.sync="comment"
                @cancel="editing = false"
                @updated-comment="editing = false"
            />
        </template>
        <template v-else>
            <div class="pl-2">
                <span class="flex items-center justify-between">
                    <div class="flex items-center mb-1">
                        <div class="font-bold" :class="{ 'text-blue-500': createdByAuthUser }">
                            {{ createdByAuthUser ? $t('clientCard.you') : comment.fullname || 'Activix' }}
                            <span class="text-gray-600 italic text-sm" v-if="comment.private">({{ $t('clientCard.private') }})</span>
                            <span class="text-gray-600 italic text-sm" v-else-if="comment.media_id && !shouldDisplayAudio">({{ $t('clientCard.audio') }})</span>
                        </div>
                        <div
                            class="flex items-center text-grey-500 ml-2"
                            v-if="depth > maxDepth + 1 && !empty(comment.parent_full_name)"
                        >
                            <icon class="text-sm mr-1" name="regular/email-action-reply" />
                            <span class="text-xs">{{ comment.parent_full_name }}</span>
                        </div>
                    </div>

                    <span class="text-grey-500 pr-2">
                        <span class="text-xs">{{ commentDateOnlyLeftOn }}</span>
                        <span class="dot" v-if="commentTimeLeftOn" />
                        <span class="text-xs">{{ commentTimeLeftOn }}</span>
                    </span>
                </span>
                <div class="flex flex-col justify-start">
                    <div class="whitespace-pre-line break-words mb-2" v-if="comment.content || !comment.file_url && !comment.media_id">
                        <span v-html="urlify(comment.content)" />
                        <time class="ml-auto text-grey-500 text-xs">{{ commentEditedOn }}</time>
                    </div>
                    <div class="py-2 w-2/3 h-20" v-if="comment.media_id">
                        <audio-player :file="audioFile" placeholder v-if="shouldDisplayAudio" />
                    </div>
                    <div v-if="comment.file_url">
                        <a class="flex items-center mb-2" :href="comment.file_url" target="_blank">
                            <img
                                :src="comment.file_url"
                                :alt="getFileName(comment.file_url)"
                                class="max-w-full max-h-64"
                                v-if="isImage(comment.file_url) && shouldDisplayImages"
                            />
                            <template v-else>
                                <icon class="text-lg" name="regular/common-file-download" />
                                <span class="mx-1">{{ getFileName(comment.file_url) }}</span>
                            </template>
                        </a>
                    </div>
                </div>
                <span class="flex items-center text-grey-500 text-sm">
                    <span class="cursor-pointer" @click="replyComment">{{ $t('clientCard.reply') }}</span>
                    <template v-if="canEditComment">
                        <span class="px-2">·</span>
                        <activix-tooltip :content="reassignedCommentTooltip">
                            <span
                                :class="{ 'cursor-pointer': !isReassignedComment, disabled: isReassignedComment }"
                                @click="openEdit"
                            >
                                {{ $t('clientCard.edit') }}
                            </span>
                        </activix-tooltip>
                    </template>
                    <activix-tooltip :content="alertSentToTooltip" v-if="alertSentTo">
                        <span class="ml-auto px-2 text-xs text-right">
                            {{ alertSentTo }}
                        </span>
                    </activix-tooltip>
                </span>
            </div>
        </template>

        <div class="flex items-start pt-4" :class="replyClasses" v-if="adding">
            <img class="rounded-full w-10 h-10 mt-2 shadow-inner" :src="profilePicture" v-if="!mdLayout" />
            <comment-form
                class="xs:ml-2"
                :comment="bufferReplyComment"
                :lead-id="leadId"
                @added-comment="clearReply"
                @cancel="clearReply"
            />
        </div>
    </div>
</template>

<script>
    // Utils
    import autolinker from 'autolinker';
    import { cloneDeep } from 'lodash-es';

    // Components
    import { mapState } from 'pinia';
    import CommentForm from './CommentForm.vue';
    import AudioPlayer from '../audio/AudioPlayer.vue';

    // Entities
    import Comment from '../../entities/Comment.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import CommentType from '../../entities/CommentType.js';

    export default {
        name: 'SubComment',

        components: {
            CommentForm,
            AudioPlayer,
        },

        props: {
            comment: {
                type: Comment,
                default: () => new Comment(),
            },
            depth: {
                type: Number,
                default: 1,
            },
            commentsFilter: {
                type: Number,
                default: CommentType.STRING,
            },
            leadId: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                editing: false,
                bufferReplyComment: new Comment(),
                deleteAudio: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            audioFile() {
                return this.comment.media;
            },

            adding() {
                return !this.comment.id || this.bufferReplyComment.isNotEmpty();
            },

            alertSentTo() {
                if (!this.comment.alert_users.length) {
                    return '';
                }

                if (this.comment.alert_users.length === 1) {
                    return [
                        this.$t('clientCard.alertSent'),
                        this.$t('general.to2'),
                        this.comment.alert_users[0].fullName,
                    ].join(' ');
                }

                return [
                    this.$t('clientCard.alertSent'),
                    this.$t('general.to2'),
                    this.comment.alert_users.length,
                    this.$tc('general.user', this.comment.alert_users.length).toLowerCase(),
                ].join(' ');
            },

            alertSentToTooltip() {
                if (this.comment.alert_users.length <= 1) {
                    return '';
                }

                let tooltip = '';

                const alertedUserIds = this.comment.alert_users.map(user => {
                    if (typeof user.id == 'string') {
                        return parseInt(user.id, 10);
                    }

                    return user.id;
                });

                this.contextAccount.users
                    .filter(user => alertedUserIds.includes(user.id))
                    .forEach(user => {
                        if (tooltip) {
                            tooltip += ', ';
                        }

                        tooltip += this.getFullName(user);
                    });

                return tooltip;
            },

            canEditComment() {
                return this.authUser.isAdmin() || this.createdByAuthUser;
            },

            commentDateOnlyLeftOn() {
                const createdAt = as_locale(this.comment.created_at, 'created_at');

                if (createdAt.isSameDay(now())) {
                    return createdAt.fromNow();
                }

                return createdAt.humanShort(true);
            },

            commentTimeLeftOn() {
                const createdAt = as_locale(this.comment.created_at, 'created_at');

                if (createdAt.isSameDay(now())) {
                    return '';
                }

                return createdAt.timeHumanShort();
            },

            commentEditedOn() {
                const createdAt = as_locale(this.comment.created_at, 'created_at');
                const updatedAt = as_locale(this.comment.updated_at, 'created_at');

                if (createdAt.isSame(updatedAt)) {
                    return '';
                }

                if (updatedAt.isSameDay(now())) {
                    return ` - ${this.$t('clientCard.edited')} ${updatedAt.fromNow()}`;
                }

                return ` - ${this.$t('clientCard.edited')} ${updatedAt.humanShort(true)}`;
            },

            createdByAuthUser() {
                return this.authUser.isSameUser(this.comment.user_id);
            },

            isReassignedComment() {
                return this.comment.request_user_id;
            },

            maxDepth() {
                return this.xsLayout ? 1 : 3;
            },

            profilePicture() {
                return this.bufferReplyComment.user.profile_picture || 'img/nobody.jpg';
            },

            shouldDisplayImages() {
                return [CommentType.ATTACHMENT, CommentType.ALL].includes(this.commentsFilter);
            },

            shouldDisplayAudio() {
                return [CommentType.AUDIO, CommentType.ALL].includes(this.commentsFilter);
            },

            reassignedCommentTooltip() {
                if (this.isReassignedComment) {
                    return this.$t('clientCard.reassignedCommentTooltip');
                }

                return '';
            },

            replyClasses() {
                let marginOffset = 12;

                if (this.xsLayout) {
                    marginOffset *= Math.min(this.depth, this.maxDepth + 1);
                } else if (this.mdLayout) {
                    marginOffset = 0;
                }

                return `pl-2 -ml-${marginOffset}`;
            },

            contactableUsers() {
                const guestGroups = this.contextAccount.guest_groups.map(group => {
                    return {
                        id: `G${group.id}`,
                        isGroup: true,
                        text: `[${group.name}]`,
                        roles: group.roles,
                        users: group.users,
                    };
                });

                const users = this.activeUsers;
                const contactableUsers = [];

                for (let i = 0; i < users.length; i++) {
                    if (users[i].phones && (users[i].id == this.authUser.id || users[i].active == true)) {
                        const cellPhone = users[i].phones.find(tmpPhone => {
                            return tmpPhone.cellphone;
                        });

                        contactableUsers.push({
                            cellphone: !empty(cellPhone),
                            id: users[i].id,
                            text: this.getFullName(users[i]),
                        });
                    }
                }

                return guestGroups.concat(contactableUsers);
            },
        },

        methods: {
            getParentCommentUser(commentParentUserId) {
                if (!commentParentUserId || this.authUser.isSameUser(commentParentUserId)) {
                    return null;
                }

                return this.contactableUsers.find(user => user.id === commentParentUserId);
            },

            urlify(text) {
                if (['attachment', 'audio'].includes(this.commentsFilter)) {
                    return '';
                }

                if (this.comment.request_user_id) {
                    text = `${this.$t('clientCard.assignationRequest')} : ${text}`;
                }

                return autolinker.link(text, {
                    sanitizeHtml: true,
                });
            },

            isImage(url) {
                const imgTypes = ['.png', '.jpg', '.jpeg'];

                return imgTypes.some(type => {
                    return url.toLowerCase().includes(type);
                });
            },

            replyComment() {
                const parentComment = cloneDeep(this.comment);
                const childComment = new Comment();
                const user = cloneDeep(this.authUser);

                childComment.lead_id = parentComment.lead_id;
                childComment.parent_id = parentComment.id;
                childComment.user_id = user.id;
                childComment.parent_user = this.getParentCommentUser(parentComment.user_id);
                childComment.user = user;
                childComment.parent_full_name = parentComment.fullname;

                this.bufferReplyComment = childComment;
            },

            clearReply() {
                this.bufferReplyComment = new Comment();
            },

            openEdit() {
                if (this.isReassignedComment) {
                    return;
                }

                this.editing = true;
            },
        },
    };
</script>
