export default {
    Tous: 'Tous',
    sci_marketview_sale: 'Taq Auto - Vente',
    sci_marketview_service: 'Taq Auto - Service',
    easydeal: 'Easy Deal',
    easy_deal: 'Easy Deal',
    activix: 'Activix',
    autopropulsion: 'Autopropulsion',
    quicktrade: 'Torque',
    vauto: 'vAuto',
    gmcanada: 'GM Canada',
    lucy: 'Lucy',
    mbca_digital: 'Mercedes Canada',
    fca_digital: 'Fiat Chrysler Canada',
    porsche_digital: 'Porsche Canada',
    polaris_digital: 'Polaris Canada',
    kia_digital: 'Kia Canada',
    harleyDavidsonDigital: 'Harley Davidson Canada',
    harley_davidson_digital: 'Harley Davidson Canada',
    nci_digital: 'Nissan-Infiniti Canada',
    nissan_digital: 'Nissan-Infiniti Canada',
    cdk_service_appt: 'CDK - Service',
    cdk_rv_service: 'CDK - Service',
    one_eighty: '1-80',
    serti_service: 'Serti - Service',
    serti_rv_service: 'Serti - Service',
    ctwizard: 'CTWizard',
    ct_wizard: 'CTWizard',
    forddirect: 'Ford Canada',
    ford_direct: 'Ford Canada',
    deskit: 'Deskit',
    scraper: 'Intégrateur',
    serti_vente: 'Serti - Vente',
    serti_sale: 'Serti - Vente',
    stellantis_digital: 'Stellantis Digital',
    dealer_vu: 'DealerVu',
    pbs_work_order: 'PBS - Work Oder',
    pbs_service: 'PBS - Service',
    pbsService: 'PBS - Service',
    maserati_digital: 'Maserati Digital',
    one_eighty_inventory: 'One-Eighty - Inventaire',
    cdk_inventory: 'CDK - Inventaire',
    serti_inventory: 'Serti - Inventaire',
    pbs_inventory: 'PBS - Inventaire',
    easydeal_inventory: 'EasyDeal - Inventaire',
    merlin: 'Merlin',
    serti_renewal: 'Serti - Renouvellement',
    bmwCanada: 'BMW Canada',
    fni_prestige: 'FNI Prestige',
    stellantisDigital: 'Stellantis Digital',
    ringcentral: 'RingCentral',
    vbdc: 'VBDC',

    cdkCustomer: 'CDK - Customer',
    cdkRvService: 'CDK - Service',
    dealerVu: 'DealerVu',
    fcaDigital: 'Fiat Chrysler Canada',
    kiaDigital: 'Kia Canada',
    mbcaDigital: 'Mercedes Canada',
    nciDigital: 'Nissan-Infiniti Canada',
    oneEighty: 'One-Eighty',
    polestar: 'Polestar',
    porscheDigital: 'Porsche Canada',
    polarisDigital: 'Polaris',
    sciMarketviewSale: 'Taq Auto - Sale',
    sciMarketviewService: 'Taq Auto - Service',
    sertiRvService: 'Serti - Service',
    sertiVente: 'Serti - Sale',
    pbs: 'PBS',
    pbsWorkOrder: 'PBS - Service',
    bmw_canada: 'BMW Canada',
    sertiRenewal: 'Serti - Renouvellement',
    fniPrestige: 'FNI Prestige',
    maseratiDigital: 'Maserati Digital',
    oneEightyInventory: 'One-Eighty - Inventaire',
    cdkInventory: 'CDK - Inventaire',
    sertiInventory: 'Serti - Inventaire',
    pbsInventory: 'PBS - Inventaire',
    easydealInventory: 'EasyDeal - Inventaire',
    mitsubishi: 'Mitsubishi',
    hyundai: 'Hyundai',
    deskitInventory: 'Deskit - Inventaire',
    deskit_inventory: 'Deskit - Inventaire',
    oneSourceInventory: 'One Source - Inventaire',
    onesourceInventory: 'One Source - Inventaire',
    one_source_inventory: 'One Source - Inventaire',
    ncar: 'NCAR',

    hondaCanada: 'Honda Canada',
    acuraCanada: 'Acura Canada',
    honda_canada: 'Honda Canada',
    acura_canada: 'Acura Canada',

    canadianBlackBook: 'Canadian Black Book',

    autovance: 'AutoVance',

    export: {
        autovance: 'AutoVance',
        deskit: 'Deskit',
        lucy: 'Lucy',
        oneEighty: 'One-Eighty',
        sertiVente: 'Merlin',
        merlin: 'Merlin',
        pbs: 'PBS',
        autopropulsion: 'Autopropulsion',
        quicktrade: 'Torque',
        vauto: 'vAuto',
        fniPrestige: 'FNI Prestige',
    },

    tooltip: {
        doublesubmit: 'Une seule soumission est autorisée',
        mandatory: "Vous devez spécifier la marque, le modèle, l'année et le NIV afin de soumettre le véhicule",
        mandatoryOnlyVin: 'Vous devez spécifier le NIV afin de soumettre le véhicule',
        mandatoryWithOdometer: "Vous devez spécifier la marque, le modèle, l'année, l'odomètre et le NIV afin de soumettre le véhicule",
        received: 'Évaluation effectuée par {0} le {1}',
        valuesCannotBeNegatives: 'La valeur et le profit ne peuvent pas avoir une valeur négative.',
        validVin: 'NIV invalide. Un NIV valide est composé de 17 caractères.',
        pbsLastNameRequired: 'Le lead doit avoir un nom complet.',
        sertiUserRequired: 'Le lead doit avoir un conseiller d\'assigné.',
    },

    label: {
        unsubmitted: 'Non soumis',
        waiting: 'Évaluation soumise le {0}, en attente...',
        received: 'Évaluation reçue',
    },
};
