export default {
    landing: {
        404: 'Oops, mauvais détour!',
        close: 'Fermer cette fenêtre',
        confirmedText: "Vous avez accepté de recevoir des communications électroniques de {dealer}. Si vous changez d'avis, vous pouvez",
        confirmedTitle: "Merci d'avoir confirmé vos préférences de communication.",
        revokeLinkText: 'révoquer votre consentement.',
        revokedText: "Vous avez révoqué votre consentement à recevoir des communications de {dealer}. Si vous changez d'avis, vous pouvez redonner votre consentement en utilisant le même lien.",
        revokedTitle: 'Consentement aux communications électroniques révoqué.',
        subscribe: 'Adhérer',
    },

    alreadyConfirmed: 'Le client a déjà donné son consentement',
    cantValidateConsent: "Impossible de valider l'adhésion pour l'instant. Veuillez réessayer plus tard.",
    confirmConsent: "Confirmer l'adhésion prolongée",
    confirmConsentMessage: 'Choisissez votre méthode de confirmation.',
    hyundaiUnknownConsent: 'Consentement au message commercial électronique inconnu',
    hyundaiConfirmedConsent: 'Consentement au message commercial électronique',
    hyundaiNoConsent: 'Pas de consentement au message commercial électronique',
    confirmedState: 'Adhésion Confirmée',
    consent: 'Adhésion',
    consentExpiration: "Fin d'adhésion",
    consentNoteIsRequired: 'La note est obligatoire',
    consentNoteIsTooShort: 'La note est trop courte',
    consentNotePlaceholder: 'Note',
    consentReasonConfirmed: 'Confirmé le {date}',
    consentRevoked: 'Révoqué',
    consentStatus: 'Adhésion',
    consentWarning: "Cette méthode de confirmation n'est pas recommandé. Assurez-vous d'avoir eu le consentement verbal, écrit ou électronique de votre client.",
    daysLeft: 'jour restant | jours restants',
    deactivate: 'Désactiver',
    expiresIn: 'Expire dans {days} jour | Expire dans {days} jours',
    expiry: 'Expiration',
    missingEmail: 'Le client doit avoir un courriel valide',
    moreInformationsButton: 'Informations loi anti-pourriel',
    moreInformationsFirstInfo: "Vous pouvez demander son consentement en tout temps. Si vous l'obtenez, vous conserverez votre droit de communication passé ce délai.",
    moreInformationsHeader: 'Sans le consentement explicite du client, la Loi canadienne anti-pourriel vous empêchera de communiquer avec lui de façon électronique à partir du {date}.',
    moreInformationsSecondInfo: 'À tout moment, le client conserve son droit de retirer son consentement. ',
    moreLawInfo: 'Pour tous les détails sur la loi, veuillez vous référer au',
    moreLawInfoLinkMessage: 'site officiel du gouvernement du canada',
    noValidEmail: 'Aucun courriel valide',
    reason: 'Raison',
    temporaryState: 'Adhésion Temporaire',
    waitingForDoubleOptIn: 'En attente de la confirmation du client',

    delays: {
        days: 'jour | jours',
        months: 'mois | mois',
        years: 'an | ans',
    },

    durations: {
        lessThanTenDays: '10 jours et moins',
        elevenToThirtyDays: '11 à 30 jours',
        oneToThreeMonths: '1 à 3 mois',
        fourToElevenMonths: '4 à 11 mois',
        twelveToTwentyFourMonths: '12 à 24 mois',
    },

    emailTypes: {
        home: 'maison',
        work: 'travail',
        other: 'autre',
    },

    errors: {
        sendConsentEmailFailed: "L'envoi du courriel a échoué, veuillez réessayer.",
        saveManualConsentFailed: 'La sauvegarde du mode manuel a échoué, veuillez réessayer.',
    },

    events: {
        appointment: 'Un rendez-vous a eu lieu le ',
        communication: 'Une communication a eu lieu le ',
        initial: 'Lead créé le ',
        sale: 'Une vente a eu lieu le ',
        visit: 'Une visite a eu lieu le ',
        contract: 'Un contrat se termine le ',
    },

    methods: {
        email: 'Courriel',
        manual: 'Manuelle',
    },

    states: {
        confirmed: 'Confirmée',
        temporary: 'Temporaire',
        expired: 'Expirée',
    },

    statuses: {
        active: 'Active',
        expired: 'Expirée',
        aboutToExpire: "Est sur le point d'expirer",
    },
};
