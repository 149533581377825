<template>
    <div
        class="flex-1 relative border rounded"
        :class="active ? 'border-gray-400' : 'border-gray-300'"
        @keyup.esc="cancel"
        v-on-clickaway="cancel"
    >
        <div
            @click.stop.prevent="onBoxClick"
            @dragover.prevent="dragAndDropping = true"
            @dragleave="dragAndDropping = false"
            @drop.prevent="dropFile"
        >
            <span class="absolute top-0 left-0 text-xs text-grey-500 pl-2" v-if="atVisible">
                @{{ bufferComment.parent_full_name }}
            </span>

            <button
                class="link-grey-light absolute top-0 right-0 text-xs p-2 z-10"
                :class="{ 'pt-5': atVisible }"
                @click.stop="cancelAndClose"
                v-if="active && !audioOpened"
            >
                <icon name="bold/close" />
            </button>

            <transition
                enter-active-class="transition duration-200"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-200"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    class="absolute inset-0 z-20 flex justify-center items-center bg-gray-100 rounded"
                    v-if="currentlyProcessing"
                >
                    <activix-spinner :size="28" :line-size="3" />
                </div>
                <div
                    class="absolute z-20 inset-0 flex flex-col items-center justify-center border-dashed border-2 border-blue-500 bg-gray-100 rounded pointer-events-none"
                    v-if="dragAndDropping"
                >
                    <icon class="text-blue-500 text-2xl mb-1" name="regular/download-button" />
                    <span>{{ $t('clientCard.dropAttachment') }}</span>
                </div>
            </transition>

            <div class="relative bg-white">
                <button
                    class="link-grey-light absolute z-40 top-0 right-0 text-xs p-2"
                    @click="audioOpened = false"
                    v-tooltip="$t('clientCard.recordDelete')"
                    v-if="audioOpened"
                >
                    <icon name="bold/close" />
                </button>
                <div class="w-full h-20" v-show="audioOpened">
                    <audio-recorder
                        class="z-10 relative"
                        :opened="audioOpened"
                        :comment="comment"
                        @input="handleAudioFile"
                        @isRecording="handleIsRecording"
                        @errorRecording="audioOpened = false"
                    />
                </div>
                <textarea-autosize
                    class="rounded-sm shadow-none transition-colors duration-200 w-full | resize-none p-2 bg-white"
                    :autosize="true"
                    :class="{ 'mt-4': atVisible }"
                    :max-height="350"
                    :min-height="64"
                    :maxlength="32000"
                    :placeholder="$t('clientCard.addCommentOrUpload')"
                    ref="textArea"
                    @focus.native="$emit('update:active', true)"
                    v-model="bufferComment.content"
                    v-if="!audioOpened && !bufferComment.media_id"
                />
                <div class="h-20" v-if="bufferComment.media">
                    <audio-player
                        :file="bufferComment.media"
                        :editing="true"
                        class="w-full"
                    />
                </div>
            </div>
        </div>

        <div class="flex items-center justify-between border-t p-2 space-x-3 pl-3 | transition duation-200" :class="active ? 'border-gray-300' : 'border-gray-200'">
            <comment-notification :comment.sync="bufferComment" :private-note="bufferComment.private" />

            <div v-tooltip="recordingButtonTooltip">
                <button
                    class="flex items-center justify-center w-6 h-6 | transition duration-200"
                    :class="[audioOpened ? 'link-blue' : 'link-grey', {'cursor-not-allowed opacity-30': hasFiles}]"
                    :disabled="hasFiles || hasText"
                    @click="audioOpened = !audioOpened"
                    v-if="!comment.id"
                >
                    <icon name="regular/microphone" class="text-lg" />
                </button>
            </div>

            <div class="flex items-center relative">
                <label :for="`file_${commentKey}`" class="flex items-center m-0" v-if="adding && !bufferComment.media">
                    <div class="w-6 h-6">
                        <icon
                            class="text-lg | transition duration-200"
                            :class="[hasFiles ? 'link-blue' : 'link-grey', {'opacity-25': audioOpened}]"
                            name="regular/attachment"
                            v-tooltip="$t('clientCard.attachFiles')"
                        />
                    </div>
                    <input
                        :id="`file_${commentKey}`"
                        class="hidden z-10 relative"
                        :class="{'cursor-not-allowed': audioOpened}"
                        multiple="multiple"
                        :name="`file_${commentKey}`"
                        :disabled="audioOpened || bufferComment.media_id"
                        type="file"
                        ref="file"
                        @change="uploadAttachments()"
                    />
                </label>
                <div class="flex items-center pointer-events-none h-4 min-w-4 p-1 shadow justify-center absolute bg-blue-500 text-sm text-white rounded-full mb-5 ml-4 font-normal" v-text="bufferComment.file.length" v-if="hasFiles" />

                <comment-upload :files="bufferComment.file" @delete="deleteFile" />
            </div>

            <div class="flex-1 mr-4">
                <div class="flex items-center" v-if="bufferComment.file_url">
                    <a class="flex items-center" :href="comment.file_url" target="_blank">
                        <icon name="regular/common-file-download" />
                        <span class="ml-2 truncate max-w-32 2xl:max-w-64">{{ getFileName(comment.file_url) }}</span>
                    </a>
                    <icon
                        class="link-grey-light ml-3 | hover:text-red-500"
                        :name="$icons.trash"
                        @click="bufferComment.file_url = ''"
                    />
                </div>
            </div>

            <icon
                :name="$icons.trash"
                class="link-grey-light mr-3 | hover:text-red-500"
                @click="deleteComment"
                v-if="!adding"
            />

            <div class="flex items-center">
                <activix-switcher
                    class="pr-4"
                    :value="bufferComment.private"
                    @input="bufferComment.private = $event"
                    v-tooltip="$t('clientCard.privateNoteInfo')"
                >
                    {{ $t('clientCard.privateNote') }}
                </activix-switcher>
                <activix-button
                    type="primary"
                    size="small"
                    @click="updateComment"
                    v-if="!adding"
                >
                    {{ $t('clientCard.save') }}
                </activix-button>

                <activix-button
                    type="primary"
                    size="small"
                    :disabled="currentlySubmiting"
                    @click="sendComment()"
                    v-if="adding"
                >
                    {{ $t('clientCard.save') }}
                </activix-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vapor from 'laravel-vapor';
    import { isEmpty } from 'lodash-es';

    // Components
    import { mapActions, mapState } from 'pinia';
    import CommentUpload from '@/components/comment/CommentUpload.vue';
    import AudioRecorder from '../audio/AudioRecorder.vue';
    import AudioPlayer from '../audio/AudioPlayer.vue';
    import CommentNotification from './CommentNotification.vue';

    // Entities
    import Comment from '../../entities/Comment.js';
    import { useGlobalStore } from '../../store/store.js';
    import Role from '../../entities/Role.js';

    export default {
        name: 'CommentForm',

        components: {
            AudioRecorder,
            CommentNotification,
            AudioPlayer,
            CommentUpload,
        },

        inject: ['commentAdded', 'commentUpdated', 'commentDeleted'],

        props: {
            comment: {
                type: Comment,
                default: () => new Comment(),
            },
            adding: {
                type: Boolean,
                default: true,
            },
            active: {
                type: Boolean,
                default: true,
            },
            leadId: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                audioOpened: false,
                dragAndDropping: false,
                bufferComment: new Comment(),
                isRecording: false,
                audioFile: null,
                uploadProgress: null,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            commentKey() {
                if (!this.comment.parent_id) {
                    return 'new';
                }

                return `${this.comment.parent_id}_${this.comment.id || 'new'}`;
            },

            currentlyProcessing() {
                if (!empty(this.audioFile) && this.$wait.is('creating.comment')) {
                    return true;
                }

                if (!empty(this.bufferComment.id) && (this.$wait.is('deleting.comment') || this.$wait.is('updating.comment'))) {
                    return true;
                }

                if (empty(this.bufferComment.content) && empty(this.bufferComment.file)) {
                    return false;
                }

                if (empty(this.bufferComment.id) && this.$wait.is('creating.comment')) {
                    return true;
                }

                return false;
            },

            atVisible() {
                return !this.createdByAuthUser && this.bufferComment.parent_full_name;
            },

            currentlySubmiting() {
                if (this.$wait.is('creating.comment') || (empty(this.bufferComment.content) && empty(this.bufferComment.file) && empty(this.audioFile))) {
                    return true;
                }

                return false;
            },

            createdByAuthUser() {
                return this.bufferComment.user_id === this.authUser.id;
            },

            hasFiles() {
                return this.bufferComment?.file?.length > 0;
            },

            hasText() {
                return this.bufferComment.content !== '';
            },

            recordingButtonTooltip() {
                if (this.hasText) {
                    return this.$t('clientCard.cantAddAudioNoteWithText');
                }

                return this.$t('clientCard.record');
            },
        },

        watch: {
            'bufferComment.file_url': {
                handler() {
                    if (isEmpty(this.bufferComment.file_url) && isEmpty(this.bufferComment.content) && isEmpty(this.bufferComment.media) && !this.adding) {
                        this.deleteComment();
                    }
                },
            },
            comment: {
                immediate: true,
                handler() {
                    this.resetComment();
                },
            },
            active: {
                immediate: true,
                async handler(newValue) {
                    if (newValue) {
                        await this.$nextTick();
                        this.focusTextArea();
                    }
                },
            },
            audioOpened(newValue) {
                if (newValue) {
                    this.bufferComment.private = this.authUser.role_id == Role.COMMERCIAL;
                } else {
                    this.bufferComment.private = false;
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            handleIsRecording(status) {
                this.isRecording = status;
            },

            handleAudioFile(file) {
                this.audioFile = file;
            },

            focusTextArea() {
                const textArea = this.$refs.textArea?.$el;

                if (textArea) {
                    textArea.focus();
                }
            },

            uploadAttachments(attachment) {
                this.bufferComment.file = attachment || this.$refs.file.files;
                this.bufferComment.file = [...this.bufferComment.file];
            },

            sendComment() {
                if (this.audioFile) {
                    this.sendAudioFile();
                } else {
                    let comment = {
                        parent_id: this.bufferComment.parent_id,
                        alert_users: this.bufferComment.alert_users
                            .filter(user => {
                                return String(user.id).indexOf('G') === -1;
                            })
                            .map(user => user.id),
                        alert: this.bufferComment.alert,
                        alert_sms: this.bufferComment.alert_sms,
                        alert_email: this.bufferComment.alert_email,
                        content: this.bufferComment.content,
                        file: this.bufferComment.file,
                        private: this.bufferComment.private,
                        lead_id: this.leadId,
                        user_id: this.authUser.id,
                        parent_full_name: this.bufferComment.parent_full_name,
                        request_user_id: this.bufferComment.request_user_id,
                    };

                    if (comment.file && comment.file.length === 1) {
                        comment = { ...comment, file: comment.file[0], content: comment.content };
                    } else if (comment.file && comment.file.length > 1) {
                        this.bufferComment.file.forEach(file => {
                            comment = { ...comment, file, content: this.bufferComment.content };
                            this.addComment(comment);
                        });

                        return;
                    }

                    this.addComment(comment);
                }
            },

            async sendAudioFile() {
                const duration = Math.floor(this.audioFile.duration);
                const audioFile = new File([this.audioFile.blobs], `${this.audioFile.name}.mp3`);
                const alertUsers = this.bufferComment.alert_users
                    .filter(user => {
                        return String(user.id).indexOf('G') === -1;
                    })
                    .map(user => user.id);

                this.$wait.start('creating.comment');

                const responseVapor = await Vapor.store(audioFile, {
                    progress: progress => {
                        this.uploadProgress = Math.round(progress * 100);
                    },
                    baseURL: this.$axios.defaults.baseURL,
                    headers: this.$axios.defaults.headers.common,
                });

                const audioNote = await this.$api.comments.storeAudioNote({
                    leadId: this.leadId,
                    parentId: this.bufferComment.parent_id,
                    key: responseVapor.key,
                    alert_users: alertUsers,
                    private: this.bufferComment.private,
                    duration,
                });

                this.$wait.end('creating.comment');

                this.audioFile = null;
                this.audioOpened = false;

                this.commentAdded(!!audioNote.parent_id);
                this.$emit('added-comment', audioNote);

                this.cancelAndClose();
            },

            async addComment(comment) {
                this.$wait.start('creating.comment');

                try {
                    const addedComment = await this.$api.comments.store({
                        comment,
                        file: comment.file,
                    });

                    if (comment.alert_users.length > 0) {
                        this.$behavior.track('Notes', { action: 'mention', users: comment.alert_users });
                    }

                    this.commentAdded(!!comment.parent_id);
                    this.$emit('added-comment', addedComment);

                    this.cancel();
                } catch (error) {
                    this.$wait.end('creating.comment');

                    throw error;
                }

                this.resetComment();
            },

            async updateComment() {
                this.$wait.start('updating.comment');

                try {
                    const payload = {
                        ...this.bufferComment,
                        alert_users: this.bufferComment.alert_users
                            .filter(user => String(user.id).indexOf('G') === -1)
                            .map(user => user.id),
                    };

                    const updatedComment = await this.$api.comments.update(this.bufferComment.id, payload);

                    this.commentUpdated(updatedComment);
                    this.$emit('updated-comment', updatedComment);

                    this.cancel();

                    this.$wait.end('updating.comment');
                } catch (error) {
                    this.$wait.end('updating.comment');
                    this.$notify.error(this.$t('automations.alerts.update.error'));

                    throw error;
                }
            },

            async deleteComment() {
                try {
                    if (this.$wait.is('deleting.comment')) {
                        return;
                    }

                    this.$wait.start('deleting.comment');

                    await this.$api.comments.destroy(this.bufferComment.id);
                    this.commentDeleted(this.bufferComment.id);
                    this.$emit('deleted-comment', this.bufferComment);

                    this.cancelAndClose();

                    this.$wait.end('deleting.comment');
                } catch (error) {
                    this.$wait.end('deleting.comment');

                    if (error.response && error.response.status !== 404) {
                        this.appendNewError({
                            code: '0085',
                            display: true,
                            error,
                            payload: {
                                comment_id: this.bufferComment.id,
                            },
                        });
                    }

                    throw error;
                }
            },

            onBoxClick() {
                this.$emit('update:active', true);
            },

            cancel() {
                this.$emit('update:active', false);
            },

            cancelAndClose() {
                this.$emit('update:active', false);
                this.$emit('cancel');
                this.resetComment();
            },

            resetComment() {
                this.bufferComment = new Comment({ ...this.comment });
            },

            dropFile(event) {
                this.bufferComment.file = null;
                this.uploadAttachments(event.dataTransfer.files);
                this.dragAndDropping = false;
                this.$emit('update:active', true);
            },

            deleteFile(index) {
                this.$delete(this.bufferComment.file, index);
            },
        },
    };
</script>
