import Enum from './Enum.js';

export default class Supplier extends Enum {
    static get entries() {
        return {
            serti_sale: 1,
            deskit: 2,
            ford_direct: 3,
            ct_wizard: 4,
            serti_service: 6,
            one_eighty: 7,
            cdk_service_appt: 8,
            nissan_digital: 10,
            porsche_digital: 11,
            fca_digital: 12,
            mbca_digital: 13,
            vauto: 14,
            activix: 15,
            easy_deal: 16,
            autopropulsion: 20,
            pbs: 21,
            honda_canada: 22,
            acura_canada: 23,
            quicktrade: 25,
            autovance: 27,
            dealer_vu: 30,
            kia_digital: 31,
            polaris_digital: 32,
            harley_davidson_digital: 33,
            pbs_work_order: 34,
            lucy: 35,
            merlin: 36,
            serti_renewal: 37,
            fni_prestige: 38,
            bmw_canada: 39,
            polestar: 40,
            maserati_digital: 43,
            one_eighty_inventory: 44,
            cdk_inventory: 45,
            easydeal_inventory: 46,
            pbs_inventory: 47,
            serti_inventory: 48,
            mitsubishi: 49,
            stellantis_digital: 50,
            ringcentral: 51,
            hyundai: 52,
            pbs_service: 53,
            one_source_inventory: 57,
            deskit_inventory: 58,
        };
    }

    static get ACTIVIX() {
        return this.entries.activix;
    }

    static get ACURA_CANADA() {
        return this.entries.acura_canada;
    }

    static get CDK_SERVICE_APPT() {
        return this.entries.cdk_service_appt;
    }

    static get CT_WIZARD() {
        return this.entries.ct_wizard;
    }

    static get DESKIT() {
        return this.entries.deskit;
    }

    static get EASY_DEAL() {
        return this.entries.easy_deal;
    }

    static get FCA_DIGITAL() {
        return this.entries.fca_digital;
    }

    static get FORD_DIRECT() {
        return this.entries.ford_direct;
    }

    static get HONDA_CANADA() {
        return this.entries.honda_canada;
    }

    static get KIA_DIGITAL() {
        return this.entries.kia_digital;
    }

    static get MBCA_DIGITAL() {
        return this.entries.mbca_digital;
    }

    static get MITSUBISHI() {
        return this.entries.mitsubishi;
    }

    static get NISSAN_DIGITAL() {
        return this.entries.nissan_digital;
    }

    static get ONE_EIGHTY() {
        return this.entries.one_eighty;
    }

    static get PBS() {
        return this.entries.pbs;
    }

    static get PBS_WORK_ORDER() {
        return this.entries.pbs_work_order;
    }

    static get PBS_SERVICE() {
        return this.entries.pbs_service;
    }

    static get POLARIS_DIGITAL() {
        return this.entries.polaris_digital;
    }

    static get PORSCHE_DIGITAL() {
        return this.entries.porsche_digital;
    }

    static get SERTI_SALE() {
        return this.entries.serti_sale;
    }

    static get SERTI_SERVICE() {
        return this.entries.serti_service;
    }

    static get VAUTO() {
        return this.entries.vauto;
    }

    static get AUTOPROPULSION() {
        return this.entries.autopropulsion;
    }

    static get QUICKTRADE() {
        return this.entries.quicktrade;
    }

    static get DEALER_VU() {
        return this.entries.dealer_vu;
    }

    static get AUTOVANCE() {
        return this.entries.autovance;
    }

    static get HARLEY_DAVIDSON_DIGITAL() {
        return this.entries.harley_davidson_digital;
    }

    static get LUCY() {
        return this.entries.lucy;
    }

    static get MERLIN() {
        return this.entries.merlin;
    }

    static get SERTI_RENEWAL() {
        return this.entries.serti_renewal;
    }

    static get FNIPRESTIGE() {
        return this.entries.fni_prestige;
    }

    static get POLESTAR() {
        return this.entries.polestar;
    }

    static get MASERATI_DIGITAL() {
        return this.entries.maserati_digital;
    }

    static get ONE_EIGHTY_INVENTORY() {
        return this.entries.one_eighty_inventory;
    }

    static get CDK_INVENTORY() {
        return this.entries.cdk_inventory;
    }

    static get EASYDEAL_INVENTORY() {
        return this.entries.easydeal_inventory;
    }

    static get PBS_INVENTORY() {
        return this.entries.pbs_inventory;
    }

    static get SERTT_INVENTORY() {
        return this.entries.serti_inventory;
    }

    static get STELLANTIS_DIGITAL() {
        return this.entries.stellantis_digital;
    }

    static get RINGCENTRAL() {
        return this.entries.ringcentral;
    }

    static get HYUNDAI() {
        return this.entries.hyundai;
    }

    static get ONE_SOURCE_INVENTORY() {
        return this.entries.one_source_inventory;
    }

    static get DESKIT_INVENTORY() {
        return this.entries.deskit_inventory;
    }

    static isSupplierImportService(supplierId) {
        return [Supplier.CDK_SERVICE_APPT, Supplier.SERTI_SERVICE].includes(supplierId);
    }

    static isSupplierDeskingTool(supplierId) {
        return [Supplier.MERLIN].includes(parseInt(supplierId, 10));
    }
}
