export default {
    account: {
        singular: 'compte',
    },
    divisions: {
        general: 'Hyundai Canada',
    },
    civility: {
        singular: 'civilité',
    },
    delivery: {
        singular: 'livraison',
        plural: 'livraisons',
    },
    deliverable: {
        singular: 'livrable',
    },
    delivered: {
        infinitive: 'livrer',
        singular: 'livré',
        plural: 'livrés',
        feminineSingular: 'livrée',
        femininePlural: 'livrées',
    },
    deliveryDate: {
        singular: 'à livrer le',
    },
    presented: {
        singular: 'visite',
    },
    province: {
        CA: 'province',
    },
    renewal: {
        singular: 'renouvellement',
        plural: 'renouvellements',
        prefix: 'le renouvellement',
        abr: 'renouv.',
    },
    renewed: {
        infinitive: 'renouveler',
        singular: 'renouvelé',
        plural: 'renouvelés',
    },
    result: {
        singular: 'résultat',
    },
    sale: {
        singular: 'vente',
        plural: 'ventes',
        prefix: 'une vente',
    },
    secondaryAdvisor: {
        singular: 'second conseiller',
    },
    sold: {
        singular: 'vendu',
        plural: 'vendus',
    },
    undelivered: {
        singular: 'non livré',
        plural: 'non livrés',
    },
    version: {
        singular: 'version',
    },
    walkIn: {
        singular: 'walk-in',
        plural: 'walk-in',
        prefix: 'a walk-in',
        abr: 'walk-in',
    },
    certified: 'Vehicle occasion certifié',
};
