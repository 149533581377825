<template>
    <activix-popover
        placement="bottom-end"
        trigger="click"
        class="flex w-full h-full"
        ref="popover"
    >
        <template #trigger>
            <activix-tooltip :content="tooltip">
                <button
                    class="relative flex flex-col items-stretch grow items-center justify-center"
                    :class="[{
                        'text-gray-500 cursor-not-allowed hover:bg-transparent': disabled,
                        'text-gray-700 hover:bg-gray-300': !disabled,
                    }, buttonClassName]"
                    @click="!disabled && $emit('click')"
                >
                    <span class="flex items-center justify-center gap-2 pointer-events-none">
                        <span
                            class="absolute"
                            :class="[indicatorColor, indicatorClassName, verticalLayout ? 'left-0 h-6 w-1.5 rounded-r' : 'top-0 w-6 h-1.5 rounded-b']"
                            v-if="indicator"
                        />

                        <slot name="before-icon" />

                        <icon
                            :class="[verticalLayout ? 'text-lg' : iconSizeClassName, iconClassName]"
                            :name="iconName"
                        />

                        <slot name="after-icon" />
                    </span>

                    <slot name="under-icon" />

                </button>
            </activix-tooltip>
        </template>
        <slot name="popoverContent" :close-popover="closePopover" />
    </activix-popover>
</template>

<script>
    import { ActivixPopover } from '@activix/ui';

    export const indicatorTypes = ['danger', 'info', 'success', 'warning'];

    export default {
        components: {
            ActivixPopover,
        },
        props: {
            buttonClassName: {
                type: [String, Array, Object],
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            iconClassName: {
                type: [String, Array, Object],
                default: '',
            },
            iconName: {
                type: String,
                required: true,
            },
            iconSizeClassName: {
                type: [String, Array, Object],
                default: 'text-xl',
            },
            indicator: {
                type: Boolean,
                default: false,
            },
            indicatorClassName: {
                type: [String, Array, Object],
                default: '',
            },
            indicatorType: {
                type: String,
                default: 'info',
                validator: value => indicatorTypes.includes(value),
            },
            tooltip: {
                type: String,
                default: '',
            },
            verticalLayout: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            indicatorColor() {
                return {
                    danger: 'bg-red-500',
                    info: 'bg-blue-500',
                    success: 'bg-green-500',
                    warning: 'bg-orange-500',
                }[this.indicatorType];
            },
        },

        methods: {
            closePopover() {
                this.$refs.popover.close();
            },
        },
    };
</script>
