export default {
    alertTitle: 'Alert',
    automation: 'Automation',
    byUser: 'By',
    campaign: 'Event',
    communicationTitle: 'Communication',
    dealer: 'Dealer',
    eventTitle: 'Event',
    inTurn: 'Who\'s Next',
    lead: 'Lead',
    markAllAsRead: 'Mark all as read',
    noAlerts: 'No alert notification',
    noCommunications: 'No communication notification',
    noEvents: 'No event notification',
    to: 'to',
    unknown: 'Unknown',
    viewMore: 'Show more...',

    communication: {
        email: 'New email',
        emailRead: 'Your email was read',
        newComment: 'Someone left you a comment',
        newCommentAssignedLead: 'Comment on an assigned lead',
        niotextIncoming: 'Incoming SMS',
        replyComment: 'Someone replied to your comment',
        replyCommentSent: 'Replied',
    },
    event: {
        appointment: 'Appointment',
        delivery: '@.capitalize:lexicon.delivery.singular',
        other: 'Other',
        phoneAppointment: 'Phone appointment',
        testDrive: 'Test drive',
        virtualAppointment: 'Virtual appointment',
    },
    general: {
        renewalAlertEquity: 'Positive equity',
        renewalAlertEquityDetails: 'lead in positive equity | leads in positive equity',
        renewalAlertEndLocation: 'End of lease',
        renewalAlertEndLocationDetails: 'lead in end of lease in {delay} months | leads in end of lease in {delay} months',
        renewalAlertEndWarranty: 'End of warranty',
        renewalAlertEndWarrantyDetails: 'lead in end of warranty in {delay} months | leads in end of warranty in {delay} months',
        renewalAlertExcessMileage: 'Estimated mileage excess warranty',
        appointmentCanceled: 'Appointment canceled',
        appointmentConfirmed: 'Appointment confirmed',
        appointmentNoShow: 'Appointment no show',
        appointmentStatusUpdated: 'Appointment presented',
        assignedLeadUpdated: 'Your lead has been modified',
        automationCompleted: 'Automation completed',
        automationNotification: 'Automation notification',
        declinedLeadDemand: 'Assignment declined',
        deliveryStatusUpdated: '@.capitalize:lexicon.delivery.singular completed',
        eventBadService: 'Bad service for an event lead',
        eventCanceledAppointment: 'Canceled appt. for an event lead',
        eventConfirmedAppointment: 'Confirmed appt. for an event lead',
        eventDatesUpdated: 'Event updated',
        eventLeadToReach: 'Event lead to contact',
        eventNewAppointment: 'New appt. for an event lead',
        eventNewPhoneAppointment: 'New phone appt. for an event lead',
        eventSent: 'Appointment assigned',
        feedbackCalculator: 'Evaluation feedback for {vehicle}.',
        invite: 'Event invitation',
        leadAppt: 'New appointment',
        leadAssigned: "You've been assigned a lead",
        leadAssignedWebOrder: 'You\'ve been assigned a web order lead',
        leadAssignedSent: 'Lead assigned',
        leadInvalid: 'Invalid lead',
        leadLost: 'Lead lost',
        leadNoDivision: 'Lead without a division',
        leadNoPhone: 'Lead without a valid phone number',
        assignLeadDemand: 'Assignment request',
        leadSale: 'New @:lexicon.sale.singular',
        leadsAssigned: '{leadCount} leads have been assigned to you',
        newCommentSent: 'Note',
        newLead: 'New lead',
        newLeadDuplicate: 'New lead duplicate',
        newLeadWebOrder: 'New web order lead',
        newLeadLoyalty: 'New loyalty lead',
        newLeadLoyaltyDuplicate: 'Duplicate loyalty lead',
        newPhoneAppointment: 'New phone appointment',
        noReplyLead: 'Pending lead',
        noReplyLeadDuplicate: 'Pending lead duplicate',
        noReplyLeadWebOrder: 'Pending web order lead',
        phoneAppointmentCanceled: 'Phone appointment canceled',
        phoneAppointmentConfirmed: 'Phone appointment confirmed',
        replyCommentSent: 'Replied',
        serviceMyClientsAppointments: 'Service appointment for one of your clients',
        serviceMyClientsVehiclePickUp: 'Planned pick up date for one of your service leads',
        serviceVehiclePickUp: 'Planned pick up date for a service lead',
        taskAssigned: "You've been assigned a task",
        taskDatesUpdated: 'Task updated',
        taskStatusUpdated: 'Task completed',
        taskSent: 'Task assigned',
        taskDeleted: 'Task Deleted',
        taskEventUnassigned: 'Task/event unassigned',
        timeLimitToAnswer: 'You have {timeLimitToAnswer} to contact the lead before being unassigned',
        unassignedFromLead: 'Unassigned from lead because you did not contact in the time limit',
        returnToMarket: 'This lead has returned to market',
        wantedVehicleCreated: 'New wanted vehicle added',

        emailRead: 'Your email was read', // @TODO Delete after UI Release
        newComment: 'Someone left you a comment', // @TODO Delete after UI Release
        newCommentAssignedLead: 'Comment on an assigned lead', // @TODO Delete after UI Release
        replyComment: 'Someone replied to your comment', // @TODO Delete after UI Release
    },
    task: {
        call: 'Call',
        csi: 'CSI',
        email: 'Email',
        sms: 'SMS',
    },
};
