import { isEmpty } from 'lodash-es';
import Vue from 'vue';
import colors from '../../tailwind.colors.js';
import ActivixDate from '../value-objects/ActivixDate.js';

const isActive = process.env.VUE_APP_ENV !== 'local';

const IntercomClient = {
    isInitialized: false,

    init(user) {
        if (!isActive || Vue.auth.impersonating() || isEmpty(user) || !user.confidentiality_agreement) {
            return;
        }

        const userPayload = {
            user_id: user.id,
            email: user.email,
            name: user.fullName,
            language_override: user.locale,
            role: user.role,
            job_title: user.post?.name,
            created_at: new ActivixDate(user.created_at).timestamp,
            company: {
                company_id: user.account.id,
                name: user.account.name,
                created_at: new ActivixDate(user.account.created_at).timestamp,
            },
        };

        if (user.hasProfilePicture()) {
            userPayload.avatar = {
                type: 'avatar',
                image_url: user.profile_picture,
            };
        }

        if (!this.isInitialized) {
            Intercom('boot', {
                app_id: 'wv9budk7',
                action_color: colors.blue[500],
                ...userPayload,
            });
        } else {
            Intercom('update', userPayload);
        }

        this.isInitialized = true;
    },

    shutdown() {
        if (!isActive) {
            return;
        }

        Intercom('shutdown');

        this.isInitialized = false;
    },

    ping() {
        if (!isActive || !this.isInitialized) {
            return;
        }

        Intercom('update', {
            last_request_at: new ActivixDate('now').timestamp,
        });
    },

    startTour(tour, delay = 500) {
        if (!isActive || !this.isInitialized) {
            return;
        }

        const tours = {
            dashboard: 59919,
            lead: 58039,
            calendar: 59890,
        };

        setTimeout(() => {
            Intercom('startTour', tours[tour]);
        }, delay);
    },
};

export default IntercomClient;
