import { ApolloLink } from 'apollo-boost';
import { forEach, isArray, isPlainObject } from 'lodash-es';
import Vue from 'vue';

import GraphCache from '@/graphql/GraphCache.js';
import GraphQuerier from '@/graphql/GraphQuerier.js';
import GraphDAL from '@/graphql/GraphDAL.js';

class GraphSynchronizer extends ApolloLink {
    static REGISTERED_TYPES = [
        'Supplier',
        'Flow',
        'UserPhone',
    ];

    static PUBLIC_CHANNEL_NAME = 'graphql';

    static ACCOUNT_CHANNEL_PREFIX = 'graphql_account_';

    static USER_CHANNEL_PREFIX = 'graphql_user_';

    wsChannels = [];

    request(operation, forward) {
        if (!Vue.feature.isEnabled('graphql_cache_sync')) {
            return forward(operation);
        }

        return forward(operation).map((data) => {
            // If the operation has the subscription channel, it's a subscription
            const exploreObjectForRegisteredTypes = (data, depth = 0) => {
                if (!data || depth > 5) {
                    return null;
                }

                if (isArray(data)) {
                    const relationIdentifiers = [];

                    forEach(data, (datum) => {
                        if (isPlainObject(datum)) {
                            const identifiers = exploreObjectForRegisteredTypes(datum, depth + 1);

                            if (identifiers) {
                                relationIdentifiers.push({
                                    ...identifiers,
                                    __typename: datum.__typename,
                                });
                            }
                        }
                    });

                    return relationIdentifiers;
                }

                if (isPlainObject(data) && (typeof data.__typename !== 'undefined' || depth === 0)) {
                    const relationIdentifiers = {};

                    forEach(data, (datum, key) => {
                        if (isPlainObject(datum) || isArray(datum)) {
                            const identifiers = exploreObjectForRegisteredTypes(datum, depth + 1);

                            if (identifiers && depth !== 0) {
                                if (isPlainObject(datum)) {
                                    relationIdentifiers[key] = {
                                        ...identifiers,
                                        __typename: datum.__typename,
                                    };
                                } else {
                                    relationIdentifiers[key] = identifiers;
                                }
                            }
                        }
                    });

                    if (depth === 0) {
                        return null;
                    }

                    const cacheData = {
                        ...data,
                        ...relationIdentifiers,
                    };

                    const cacheKey = this.getCacheKeyForObject(data);

                    // TODO Should we have any sort of mechanism to force identifiers to be includes if missing from requested fields?
                    if (!cacheKey) {
                        return null;
                    }

                    GraphCache.partialUpdate(cacheKey, cacheData);

                    if (GraphSynchronizer.REGISTERED_TYPES.includes(data.__typename)) {
                        this.subscribeToData(data);
                    }

                    return this.extractObjectIdentifiers(data);
                }

                return null;
            };

            exploreObjectForRegisteredTypes(data?.data);

            return data;
        });
    }

    listen(channelName) {
        if (!this.wsChannels[channelName] && typeof Vue.prototype.$broadcasting !== 'undefined') {
            this.wsChannels[channelName] = Vue.prototype.$broadcasting.echo.private(channelName);
            this.wsChannels[channelName].listenToAll(async (event, data) => {
                const cacheKey = GraphQuerier.getCacheKeyForObject({
                    __typename: data.type,
                    ...data.identifiers,
                });

                const cachedItem = await GraphCache.get(cacheKey);

                if (!cachedItem) {
                    return;
                }

                await GraphDAL.find(cacheKey, data.changes, false);
            });
        }
    }

    subscribeToData(data) {
        if (data.account_id && !this.wsChannels[`${GraphSynchronizer.ACCOUNT_CHANNEL_PREFIX}${data.account_id}`]) {
            this.listen(`${GraphSynchronizer.ACCOUNT_CHANNEL_PREFIX}${data.account_id}`);
        } else if (data.user_id && !this.wsChannels[`${GraphSynchronizer.USER_CHANNEL_PREFIX}${data.user_id}`]) {
            this.listen(`${GraphSynchronizer.USER_CHANNEL_PREFIX}${data.user_id}`);
        } else if (!this.wsChannels[GraphSynchronizer.PUBLIC_CHANNEL_NAME]) {
            this.listen(GraphSynchronizer.PUBLIC_CHANNEL_NAME);
        }
    }

    getCacheKeyForObject(object) {
        return GraphQuerier.getCacheKeyForObject(object);
    }

    extractObjectIdentifiers(object) {
        switch (object.__typename) {
            case 'AccountProduct':
            case 'AccountSupplier':
                return {
                    id: object.id,
                    account_id: object.account_id,
                };
            case 'FlowUser':
                return {
                    id: object.id,
                    flow_id: object.flow_id,
                };
            default:
                return {
                    id: object.id,
                };
        }
    }
}

export default GraphSynchronizer;
