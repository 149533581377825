import DashboardType from '../entities/DashboardType.js';

const dashboardMeta = {
    accountSelect: {
        disabled: false,
        showGroups: true,
    },
    header: {
        account: true,
        notification: true,
        search: true,
        team: true,
        settings: true,
    },
    saveInUrl: ['accountId', 'groupId', 'userId'],
};

export default [
    { path: '/dashboard/*', redirect: to => to.path.replace('dashboard/', 'dashboards/') },
    { path: '/sale-table/management', redirect: { name: 'objectives.saleTable' } },
    { path: '/objectives/activity-report', redirect: { name: 'objectives.activityReport' } },
    {
        path: '/dashboards',
        component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/Redirect/Base.vue'),
        children: [
            {
                path: 'all-lead',
                name: 'dashboards.allLead',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/AllLead.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.ALL,
                },
            },
            {
                path: 'loyalty',
                name: 'dashboards.loyalty',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/Loyalty.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.LOYALTY,
                },
            },
            {
                path: 'renewal',
                name: 'dashboards.renewal',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/Renewal.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.RENEWAL,
                },
            },
            {
                path: 'web-order',
                name: 'dashboards.webOrder',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/WebOrder.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.WEB_ORDER,
                },
            },
            {
                path: 'lead-xpress',
                name: 'dashboards.leadXpress',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/LeadXpress.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.LEAD_XPRESS,
                },
            },
            {
                path: 'web-boost',
                name: 'dashboards.webBoost',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/WebBoost.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.WEB_BOOST,
                },
            },
            {
                path: 'phone-up',
                name: 'dashboards.phoneUp',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/PhoneUp.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.PHONE_UP,
                },
            },
            {
                path: 'walk-in',
                name: 'dashboards.walkIn',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/WalkIn.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.WALK_IN,
                },
            },
            {
                path: 'sale-table',
                name: 'dashboards.saleTable',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/SaleTable.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.SALE,
                    accountSelect: {
                        disabled: false,
                        showGroups: false,
                    },
                },
            },
            {
                path: 'commercial',
                name: 'dashboards.commercial',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/Commercial.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.COMMERCIAL,
                },
            },
            {
                path: 'event',
                name: 'dashboards.event',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/Event.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.EVENT,
                },
            },
            {
                path: 'service',
                name: 'dashboards.service',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/Service.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.SERVICE,
                },
            },
            {
                path: 'activity-report',
                name: 'dashboards.activityReport',
                component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards/Redirect/ActivityReportVersionSelector.vue'),
                meta: {
                    ...dashboardMeta,
                    dashboard: DashboardType.ACTIVITY,
                    header: {
                        account: true,
                        notification: true,
                        search: true,
                        team: false,
                        settings: true,
                    },
                },
            },
        ],
    },
    {
        path: '/dashboards/activity-report/objectives',
        name: 'objectives.activityReport',
        component: () => import(/* webpackChunkName: "objectives-activity-report" */ '../views/Objectives/ActivityReport.vue'),
        meta: {
            dashboard: DashboardType.ACTIVITY,
            accountSelect: {
                disabled: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/dashboards/sale-table/objectives',
        name: 'objectives.saleTable',
        component: () => import(/* webpackChunkName: "objectives-sale-table" */ '../views/Objectives/SaleTable.vue'),
        meta: {
            dashboard: DashboardType.SALE,
            accountSelect: {
                disabled: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
];
