/* eslint-disable camelcase */
import { get, isEmpty } from 'lodash-es';
import { globalStore } from '@/pinia/storeHelper.js';
import Supplier from '@/entities/Supplier.js';
import { formatPhone } from '../utils/index.js';
import Model from './Model.js';
import User from './User.js';
import CommunicationType from './CommunicationType.js';
import LeadVehicleType from './LeadVehicleType.js';
import AccountGuestGroup from './AccountGuestGroup.js';
import ClientCardSection from './ClientCardSection.js';
import AccountType from './AccountType.js';
import LeadVehicle from './LeadVehicle.js';

export default class Account extends Model {
    static get defaults() {
        return {
            campaigns: [],
            children: [],
            client_card_fields: {
                exchange_vehicle: {},
                general_info: {},
                performance: {},
                process: {},
                wanted_vehicle: {},
            },
            custom_fields: [],
            default_deliveryman_user_id: null,
            default_monthly_mileage: null,
            phone_provider: 'twilio',
            flows: [],
            lead_forms: [],
            parents: [],
            phone_providers: [],
            products: [],
            providers: [],
            sources: [],
            sourcesWithProvider: [],
            response_templates: [],
            suppliers: [],
            teams: [],
            users: [],
            guest_groups: [],
            renewal_alert_options: {
                equity_base: 'rough',
                end_location_notifications: [],
                end_warranty_notifications: [],
                settings: [],
            },
            restrict_to_activix: false,
            imap_ignored_domains: [],
            delivery_guest_group_id: null,
        };
    }

    /**
     * Constants
     */
    static get ACTIVIX() {
        return 1;
    }

    static get DEMO() {
        return process.env.VUE_APP_ENV === 'local' ? 5 : 44;
    }

    static get V12() {
        return 824;
    }

    static get ACTIVIX_INTERNAL() {
        return 2510;
    }

    static get ACTIVIX_RECRUTEMENT() {
        return 1190;
    }

    static get AUTO_ONE_GROUP() {
        return 7790;
    }

    /**
     * Getters
     */

    get children() {
        if (this.loaded('children')) {
            return this.load('children');
        }

        const children = this._children.map(child => new Account(child));
        this.store('children', children);

        return children;
    }

    get parents() {
        if (this.loaded('parents')) {
            return this.load('parents');
        }

        const parents = this._parents.map(parent => new Account(parent));
        this.store('parents', parents);

        return parents;
    }

    get users() {
        if (this.loaded('users')) {
            return this.load('users');
        }

        const users = this._users.map(user => new User(user)).sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.store('users', users);

        return users;
    }

    get guest_groups() {
        if (this.loaded('guest_groups')) {
            return this.load('guest_groups');
        }

        const groups = this._guest_groups.map(group => new AccountGuestGroup(group));
        this.store('guest_groups', groups);

        return groups;
    }

    get formatedPhone() {
        return formatPhone(this.phone);
    }

    get activeUsers() {
        return this.users.filter(user => user.active && !user.hide_in_user_select);
    }

    get usersAndSupplierUsers() {
        if (this.loaded('usersAndSupplierUsers')) {
            return this.load('usersAndSupplierUsers');
        }

        const users = [];
        const supplierUsers = [];

        const addUser = user => {
            users.push(user);
        };

        const addSupplierUser = supplier => {
            if (supplierUsers.some(user => user.id == supplier.user_id)) {
                return;
            }

            supplierUsers.push(
                new User({
                    id: supplier.user_id,
                    supplier_id: supplier.id,
                    first_name: supplier.user_fullname || supplier.name,
                }),
            );
        };

        this.users.forEach(addUser);
        this.suppliers.forEach(addSupplierUser);

        if (this.hasNioText()) {
            users.push(
                new User({
                    id: User.NIOTEXT_USER,
                    first_name: 'NioText',
                    active: true,
                }),
            );
        }

        if (this.children.length) {
            this.children.forEach(childAccount => {
                childAccount.users.forEach(addUser);
                childAccount.suppliers.forEach(addSupplierUser);
            });
        }

        if (this.parents.length) {
            this.parents.forEach(parentAccount => {
                parentAccount.users.forEach(addUser);
                parentAccount.suppliers.forEach(addSupplierUser);
            });
        }

        const usersAndSupplierUsers = [...users, ...supplierUsers].sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.store('usersAndSupplierUsers', usersAndSupplierUsers);

        return usersAndSupplierUsers;
    }

    get activeUsersAndSupplierUsers() {
        return this.usersAndSupplierUsers.filter(user => {
            if (!user.active && user.supplier_id === null && !user.api_user) {
                return false;
            }

            if (!user.parent_user_id) {
                return true;
            }

            if (!this.usersAndSupplierUsers.some(u => u.id === user.parent_user_id)) {
                return true;
            }

            return false;
        });
    }

    get advisorUsers() {
        return this.activeUsers.filter(user => user.isAdvisor() || user.isDirector());
    }

    get bdcUsers() {
        return this.activeUsers.filter(user => user.isBdc() || user.isBdcDirector() || user.isDirector());
    }

    get commercialUsers() {
        return this.activeUsers.filter(user => user.isCommercialDirector() || user.isDirector());
    }

    get directorUsers() {
        return this.activeUsers.filter(user => user.isDirector());
    }

    get serviceAdvisorUsers() {
        return this.activeUsers.filter(user => user.isServiceAdvisor() || user.isServiceDirector());
    }

    get serviceAgentUsers() {
        return this.activeUsers.filter(user => user.isServiceAgent() || user.isServiceDirector());
    }

    get serviceUsers() {
        return this.activeUsers.filter(user => user.isService());
    }

    get notServiceUsers() {
        return this.activeUsers.filter(user => !user.isService());
    }

    get activeTeams() {
        return this.teams.filter(team => team.active).sort((a, b) => a.number - b.number);
    }

    get outgoingPhoneProviders() {
        return this.phone_providers.filter(p => {
            return p.communication_type_id == CommunicationType.OUTGOING;
        });
    }

    get allForms() {
        return [...this.accountForms, ...this.defaultForms].sort((a, b) => a.label.localeCompare(b.label));
    }

    get accountForms() {
        return this.lead_forms
            .map(form => {
                return {
                    id: form.id,
                    label: this.getFormName(form),
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    get defaultForms() {
        return globalStore().configs.leadForms
            .map(form => {
                return {
                    id: form.id,
                    label: this.getFormName(form),
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    get sources() {
        if (this.loaded('sources')) {
            return this.load('sources');
        }

        const sources = [];
        const addSourceToList = (newSource, currentAccount) => {
            const sourceAlreadyInList = sources.some(source => source.name.trim() === newSource.name.trim());

            if (sourceAlreadyInList) {
                return;
            }

            if (!newSource.display_on_listing && !currentAccount.display_approved_sources) {
                return;
            }
            sources.push(newSource);
        };

        this._sources.forEach((newSource) => addSourceToList(newSource, this));

        if (this.children) {
            this.children.forEach(childAccount => {
                childAccount._sources.forEach((newSource) => addSourceToList(newSource, childAccount));
            });
        }

        this.store('sources', sources);

        return sources;
    }

    get sourcesWithProvider() {
        if (this.loaded('sourcesWithProvider')) {
            return this.load('sourcesWithProvider');
        }

        const sources = [];
        const addSourceToList = newSource => {
            const sourceInList = this._sources.filter(source => source.name.trim() === newSource.name.trim()).length > 1;

            sources.push({
                ...newSource,
                name: sourceInList ? `${newSource.name} (${newSource.provider?.name})` : newSource.name,
            });
        };

        this._sources.forEach(addSourceToList);

        if (this.children) {
            this.children.forEach(childAccount => {
                childAccount._sources.forEach(addSourceToList);
            });
        }

        this.store('sourcesWithProvider', sources);

        return sources;
    }

    get providers() {
        if (this.loaded('providers')) {
            return this.load('providers');
        }

        const providers = [];
        const addProviderToList = source => {
            if (!source.provider) {
                return;
            }

            const providerAlreadyInList = providers.some(provider => {
                return provider.name.trim() === source.provider.name?.trim();
            });

            if (providerAlreadyInList) {
                return;
            }

            providers.push({
                id: source.provider.id,
                name: source.provider.name,
                display_name_fr: source.provider.display_name_fr,
                display_name_en: source.provider.display_name_en,
            });
        };

        this._sources.filter(source => source.active && source.display_on_listing).forEach(addProviderToList);

        if (this.children) {
            this.children.forEach(childAccount => {
                childAccount._sources.filter(source => source.active && source.display_on_listing).forEach(addProviderToList);
            });
        }

        this.store('providers', providers);

        return providers;
    }

    get exportableVehicleSuppliers() {
        return this.suppliers.filter(supplier => {
            return LeadVehicle.exportableSuppliers.includes(supplier.id) && supplier.outbound;
        });
    }

    /**
     * Setters
     */

    set children(children) {
        this.free('children');
        this.free('usersAndSupplierUsers');

        this._children = children;
    }

    set parents(parents) {
        this.free('parents');
        this.free('usersAndSupplierUsers');

        this._parents = parents;
    }

    set users(users) {
        this.free('users');
        this.free('usersAndSupplierUsers');

        this._users = users;
    }

    set guest_groups(groups) {
        this.free('guest_groups');

        this._guest_groups = groups;
    }

    set sources(sources) {
        this.free('sources');

        this._sources = sources;
    }

    set sourcesWithProvider(sources) {
        this.free('sourcesWithProvider');

        this._sourcesWithProvider = sources;
    }

    set providers(providers) {
        this.free('providers');

        this._providers = providers;
    }

    /**
     * Specifications
     */

    hasOption(option) {
        return !!this[option];
    }

    hasNioText() {
        return this.niotext && this.niotext_phone;
    }

    enabledWaitingSale() {
        return !!this.waiting_sale_option && !!this.waiting_sale_date;
    }

    childrenHasNioText() {
        return this.children.some(child => child.hasNioText());
    }

    hasMessenger() {
        return !!this.facebook_id;
    }

    hasSupplier(supplierId) {
        return this.suppliers.some(supplier => {
            return supplier.id == supplierId && supplier.inbound && supplier.outbound;
        });
    }

    hasInventorySupplier() {
        return this.suppliers.some(supplier => {
            return [
                Supplier.ONE_EIGHTY_INVENTORY,
                Supplier.CDK_INVENTORY,
                Supplier.EASYDEAL_INVENTORY,
                Supplier.PBS_INVENTORY,
                Supplier.SERTT_INVENTORY,
                Supplier.ONE_SOURCE_INVENTORY,
                Supplier.DESKIT_INVENTORY,
            ].includes(supplier.id);
        });
    }

    hasSuppliersUserSetting() {
        return this.suppliers.some(supplier => supplier.user_setting);
    }

    hasHyundaiSupplier() {
        return this.suppliers.some(supplier => supplier.id === Supplier.HYUNDAI);
    }

    isParent() {
        return !!this.account_manager;
    }

    isChild() {
        return !!this.parents.length;
    }

    isCustom() {
        return this.type == AccountType.CUSTOM;
    }

    isDealer() {
        return this.type == AccountType.DEALER;
    }

    isPolestar() {
        return this.type == AccountType.POLESTAR;
    }

    getAllCustomFields() {
        let customFields = this.custom_fields;

        if (!this.parents || isEmpty(this.parents)) {
            return customFields;
        }

        this.parents.forEach(parentAccount => {
            customFields = customFields.concat(
                parentAccount.custom_fields.map(customField => {
                    customField.parentName = parentAccount.name;

                    return customField;
                }),
            );
        });

        return customFields;
    }

    getLeadCustomFields() {
        return this.getAllCustomFields()
            .filter(customField => ClientCardSection.isLeadField(customField.section));
    }

    getLeadVehicleCustomFields() {
        return this.getAllCustomFields()
            .filter(customField => ClientCardSection.isVehicleField(customField.section));
    }

    getSectionCustomFields(section) {
        return this.getAllCustomFields().filter(customField => customField.section === section);
    }

    getSectionActiveCustomFields(section) {
        return this.getAllCustomFields().filter(customField => customField.visible && customField.section === section);
    }

    getCustomFieldName(field) {
        const fieldObject = this.getAllCustomFields().find(customField => {
            return customField.field == field;
        });
        return fieldObject ? fieldObject.name : field.replace('custom_', '');
    }

    getCustomFieldById(fieldId) {
        return this.getAllCustomFields().find(customField => {
            return customField.id == fieldId;
        });
    }

    getCustomFieldByField(field) {
        return this.getAllCustomFields().find(customField => {
            return customField.field == field;
        });
    }

    hasSectionCustomFields(section) {
        return this.getSectionCustomFields(section).some(customField => customField.visible);
    }

    hasGeneralInfoField(field, fallback = true) {
        field = Account.normalizeClientCardFieldNameForValidation(field);
        return get(this.client_card_fields, `general_info.${field}`, fallback);
    }

    hasPerformanceField(field, fallback = true) {
        field = Account.normalizeClientCardFieldNameForValidation(field);
        return get(this.client_card_fields, `performance.${field}`, fallback);
    }

    hasProcessField(field, fallback = true) {
        field = Account.normalizeClientCardFieldNameForValidation(field);
        return get(this.client_card_fields, `process.${field}`, fallback);
    }

    hasVehicleField(field, type, fallback = true) {
        switch (type) {
            case 'exchange':
            case 'exchange_vehicle':
                return this.hasExchangeVehicleField(field, fallback);
            case 'wanted':
            case 'wanted_vehicle':
                return this.hasWantedVehicleField(field, fallback);
            default:
                return fallback;
        }
    }

    hasExchangeVehicleField(field, fallback = true) {
        field = Account.normalizeClientCardFieldNameForValidation(field);
        return get(this.client_card_fields, `exchange_vehicle.${field}`, fallback);
    }

    hasWantedVehicleField(field, fallback = true) {
        field = Account.normalizeClientCardFieldNameForValidation(field);
        return get(this.client_card_fields, `wanted_vehicle.${field}`, fallback);
    }

    getFormName(form) {
        return form.display_name_fr || form.display_name_en
            ? `${form.display_name_fr} / ${form.display_name_en}`
            : form.name;
    }

    getUserById(userId) {
        let user = this.users.find(user => user.id === userId);

        if (!user && this.children.length) {
            this.children.forEach(childAccount => {
                user = user || childAccount.users.find(user => user.id === userId);
            });
        }

        if (!user && this.parents.length) {
            this.parents.forEach(parentAccount => {
                user = user || parentAccount.users.find(user => user.id === userId);
            });
        }

        return user;
    }

    haveCustomPhoneProvider() {
        return this.phone_provider != 'twilio';
    }

    hasClientCardField(field, vehicleType = '') {
        field = Account.normalizeClientCardFieldNameForValidation(field);

        const generalInfo = [
            'budget',
            'communication_preference',
            'sector',
            'rating',
            'lead_type',
            'lead_form',
            'created_method',
            'source',
            'division',
            'comment',
            'campaign',
            'segment',
            'storage',
            'client_number',
            'called_count',
            'call_date',
            'prepaid',
            'business',
            'loyalty',
            'referrer',
            'search_term',
            'keyword',
            'navigation_history',
            'do_not_market',
            'non_consumer_initiated',
        ];
        const performance = [
            'dealer_tour',
            'deposit',
            'qualification',
            'refinanced',
            'walk_around',
            'phone_appointment',
            'appointment',
            'presented',
            'sale_by_phone',
            'sale',
            'renew',
            'road_test',
            'long_term',
            'take_over',
            'be_back',
            'write_up',
        ];
        const process = [
            'available',
            'deliverable',
            'discounted',
            'gas',
            'recorded',
            'lead_bank',
            'progress_state',
            'approved',
            'delivery',
            'waiting_sale',
            'prepared',
            'inspected',
            'delivered',
            'csi',
        ];
        const vehicle = [
            'allowed_mileage',
            'bodyType',
            'budget',
            'category',
            'category_rv',
            'color_exterior',
            'color_interior',
            'comment',
            'drivingWheels',
            'end_contract_date',
            'engine',
            'frequency',
            'fuel',
            'initial_cash',
            'length',
            'mechanical',
            'mileage',
            'modality',
            'offer_number',
            'payment',
            'payment_with_tax',
            'price',
            'profit',
            'rate',
            'residual',
            'security_deposit',
            'sleeping',
            'stock',
            'term',
            'transmission',
            'vin',
            'vin_decoded',
            'warranty',
            'weight',
            'year',
        ];
        const exchangeVehicle = [
            'accidented_damage_cost',
            'balance',
            'condition',
            'end_warranty_date',
            'extended_warranty',
            'institution',
            'intention',
            'license_plate',
            'link',
            'mileage_excess',
            'purchase_date',
            'recall',
            // 'refinance',
            // 'renewal',
            'requested',
            'sold_by',
            'sold_date',
            'tire_type',
            'value',
            ...vehicle,
        ];
        const wantedVehicle = [
            'accessories',
            'documentation',
            'order_number',
            'preparation',
            'suffix',
            'tire',
            'total',
            ...vehicle,
        ];

        switch (true) {
            case generalInfo.includes(field):
                return this.hasGeneralInfoField(field);
            case performance.includes(field):
                return this.hasPerformanceField(field);
            case process.includes(field):
                return this.hasProcessField(field);
            case vehicleType == LeadVehicleType.EXCHANGE && exchangeVehicle.includes(field):
                return this.hasExchangeVehicleField(field);
            case vehicleType == LeadVehicleType.WANTED && wantedVehicle.includes(field):
                return this.hasWantedVehicleField(field);
            default:
                return true;
        }
    }

    static normalizeClientCardFieldNameForValidation(field) {
        switch (field) {
            case 'appointment_date':
                return 'appointment';
            case 'available_date':
                return 'available';
            case 'bank_id':
                return 'institution';
            case 'be_back_date':
                return 'be_back';
            case 'body_type':
                return 'bodyType';
            case 'budget_max':
            case 'budget_min':
                return 'budget';
            case 'deliverable_date':
                return 'deliverable';
            case 'delivered_date':
                return 'delivered';
            case 'delivery_date':
                return 'delivery';
            case 'discounted_date':
                return 'discounted';
            case 'division_id':
                return 'division';
            case 'driving_wheels':
                return 'drivingWheels';
            case 'extended_warranty':
                return 'warranty';
            case 'form_normalized':
                return 'lead_form';
            case 'lead_bank_id':
                return 'lead_bank';
            case 'lead_type_id':
                return 'lead_type';
            case 'length_max':
            case 'length_min':
                return 'length';
            case 'offer':
                return 'offer_number';
            case 'presented_date':
                return 'presented';
            case 'source_id':
                return 'source';
            case 'recorded_date':
                return 'recorded';
            case 'refinanced_date':
            case 'refinanced_type':
                return 'refinanced';
            case 'road_test_date':
                return 'road_test';
            case 'sale_date':
                return 'sale';
            case 'stock_state':
                return 'stock';
            case 'take_over_date':
                return 'take_over';
            case 'year':
                // Required as the client_card_field for min/max year has the same name
                return 'year_current';
            case 'year_max':
            case 'year_min':
                return 'year';
            default:
                return field;
        }
    }
}
