export default {
    in_stock: 'En stock',
    available: 'Disponible',
    unavailable: 'Indisponible',
    order: 'Commandé',
    reserved: 'Réservé',
    locate: 'Locate',
    invoice: 'Facturé',
    production: 'En production',
    transit: 'En transit',
    hold: 'En attente',
    sold: 'Vendu',
};
