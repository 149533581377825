export default {
    showMore: 'Show more...',
    pageTitle: 'Calendar',
    show: 'Show',
    hide: 'Hide',
    agenda: 'Agenda',
    atTaskEventTime: 'At time',
    toggleCallOn: 'Show calls',
    toggleCallOff: 'Hide calls',
    toggleCsiOn: 'Show CSIs',
    toggleCsiOff: 'Hide CSIs',
    toggleEmailOn: 'Show emails',
    toggleEmailOff: 'Hide emails',
    toggleEmailSmsOn: 'Show emails / SMSs',
    toggleEmailSmsOff: 'Hide emails / SMSs',
    toggleEventOn: 'Show appointments',
    toggleEventOff: 'Hide appointments',
    toggleDeliveryOn: 'Show @:lexicon.delivery.plural',
    toggleDeliveryOff: 'Hide @:lexicon.delivery.plural',
    toggleDeliveryGasFullOn: 'Show @:lexicon.delivery.plural with fuel',
    toggleDeliveryGasFullOff: 'Hide @:lexicon.delivery.plural with fuel',
    toggleDeliveryGasEmptyOn: 'Show @:lexicon.delivery.plural without fuel',
    toggleDeliveryGasEmptyOff: 'Hide @:lexicon.delivery.plural without fuel',
    toggleOtherOn: 'Show other tasks',
    toggleOtherOff: 'Hide other tasks',
    toggleAgendaViewOn: 'Agenda View',
    toggleAgendaViewOff: 'List View',
    minutesBefore: 'minutes before',
    hoursBefore: 'hours before',
    before: 'before',
    nineAm: 'At 9 am the same day',
    ninePm: 'At 9 pm the day before',
    reminders: 'Default reminders',
    eventReminders: 'Default event reminders',
    taskReminders: 'Default task reminders',
    taskRemindersTooltip: 'These reminders only apply to tasks (Call, Email, SMS and CSI), not to events.',
    eventRemindersTooltip: 'These reminders only apply to events (Appointments, @.capitalize:lexicon.delivery.plural and Others), not to tasks.',
    vehicle: 'Vehicle',
    commercial: 'F&I',
    task: 'Task',
    legend: {
        appt: 'Scheduled appointment',
        apptCompleted: 'Completed appointment',
        apptCall: 'Phone appointment',
        apptNoShow: 'Expired appointment, no show',
        apptDialogs: 'Scheduled V12 event appointment',
        apptDialogsCompleted: 'Completed V12 event appointment',
        apptDialogsNoShow: 'Expired V12 event appointment, no show',
        delivery: 'Scheduled @:lexicon.delivery.singular',
        deliveryCompleted: 'Completed @:lexicon.delivery.singular',
        contact: 'Scheduled contact event',
        contactCompleted: 'Completed contact event',
        csiOther: 'Scheduled CSI / Other event',
        csiOtherCompleted: 'Completed CSI / Other event',
        expiredEventNoAction: 'Expired event, no action',
        appointmentCanceledByCustomer: 'Appointment canceled by customer',
    },
    filters: {
        event: 'Event type',
        leadType: 'Lead type',
        automated: 'Automated',
        role: 'Role',
        status: 'Status',
        division: 'Division',
        childAccount: 'Account',
        user: 'User',
        createdBy: 'Created by',
        updatedBy: 'Updated by',
        eventTypes: {
            appointment: 'Appointment',
            phoneAppointment: 'Phone appt.',
            virtualAppointment: 'Virtual appointment',
            delivery: '@.capitalize:lexicon.delivery.singular',
            testDrive: 'Test drive',
            call: 'Call',
            email: 'Email',
            sms: 'SMS',
            csi: 'CSI',
            other: 'Other',
        },
        statusTypes: {
            past: 'Past',
            future: 'Future',
            completed: 'Completed',
            uncompleted: 'Not completed',
            uncompletedPast: 'Past and not completed',
            confirmed: 'Confirmed',
            noShow: 'No show',
            prepared: '@:clientCard.prepared',
            notPrepared: 'Not @:clientCard.prepared',
            withGas: 'Fueled',
            withoutGas: 'Empty',
        },
        filter: 'Filter',
        selectFilters: 'Select desired filters',
        apply: 'Apply',
        clearAll: 'Clear All',
        clear: 'Clear',
    },
    toggleColorScheme: 'Toggle color scheme',
    modals: {
        view: {
            title: 'Save filters',
            name: 'Title',
        },
        colorScheme: {
            title: '@:error.warning',
            description: 'This color scheme does not include a visual cue for identifying divisions.',
            proceed: '@:general.wishToProceed',
        },
    },
    saveCalendarView: 'Save the view',
    editCalendarView: 'Edit the view',
    deleteCalendarView: 'Delete the view',
    deleteCalendarViewConfirmation: 'Your custom view will be deleted permanently',
    uniqueCalendarViewNameWarning: 'The calendar view name must be unique',
    limitedAccessToTasks: "Your user profile does not allow access to other users' tasks",
    visibleEvents: 'Visible tasks',
};
