import Vue from 'vue';
import { importIcons } from '@/utils/icon';

importIcons();

const icons = {
    account: 'regular/shop',
    add: 'bold/add',
    advisor: 'regular/accessories-tie',
    appointment: 'regular/calendar-3',
    attempted: 'regular/password-desktop-approved',
    bdc: 'regular/headphones-customer-support',
    beBack: 'regular/data-transfer-three-back-forth-back',
    camera: 'regular/meeting-camera',
    collapse: 'regular/arrow-up-1',
    commercialDirector: 'regular/cash-briefcase',
    csi: 'regular/like-1',
    delivery: 'regular/car-1',
    deposit: 'regular/cash-payment-bag',
    duplicate: 'regular/multiple-neutral-2',
    email: 'regular/email-action-unread',
    emailVideo: 'regular/drawer-play',
    emailBold: 'bold/email-action-unread',
    event: 'regular/megaphone-1',
    excluded: 'regular/alert-diamond',
    expand: 'regular/arrow-down-1',
    homeRoadTest: 'regular/house-chimney-2',
    homeVisit: 'regular/house-chimney-2',
    inTurn: 'regular/button-shuffle',
    inStock: 'regular/login-keys',
    leadXpress: 'regular/earth-1',
    leadLost: 'regular/single-neutral-actions-block',
    loading: 'regular/button-refresh-arrows',
    lost: 'regular/car-actions-disable-1',
    loyalty: 'regular/award-ribbon-star-3',
    meetingOff: 'regular/single-neutral-actions-block',
    meetingOn: 'regular/single-neutral-actions-check-2',
    messenger: 'regular/social-media-facebook-1',
    navigationDotsVertical: 'bold/navigation-menu-vertical',
    navigationDotsHorizontal: 'bold/navigation-menu-hotizontal',
    pencilWrite: 'regular/pencil-write',
    phone: 'regular/phone',
    phoneAppointment: 'regular/phone-landline',
    phoneBold: 'bold/phone',
    reached: 'regular/single-neutral-actions-check-2',
    renewal: 'regular/transportation-ticket-car-transfer-1',
    roadTest: 'regular/road-straight',
    sale: 'regular/money-bag-dollar',
    service: 'regular/wench-1',
    singleNeutralRemove: 'regular/single-neutral-actions-remove',
    sms: 'regular/messages-bubble-typing-1',
    smsBold: 'bold/messages-bubble-typing-1',
    takeOver: 'regular/police-man-1',
    testDrive: 'regular/road-woods',
    time: 'regular/stopwatch',
    timeClockCircle: 'regular/time-clock-circle',
    trash: 'regular/trash-1',
    upSale: 'regular/arrow-thick-up',
    virtualAppointment: 'regular/meeting-camera',
    visit: 'regular/walking',
    warning: 'regular/alert-triangle',
    webBoost: 'regular/stopwatch',
    webOrder: 'regular/shopping-cart-check',
};

const VueIcons = {
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        VueInstance.icons = icons;
        VueInstance.prototype.$icons = icons;
    },
};

Vue.use(VueIcons);

export default icons;
