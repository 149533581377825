export default {
    alerts: {
        deletionInProgress: 'Suppression en cours, ceci peut prendre plusieurs heures...',
        presentedDateAndService: 'Seuls les leads avec une date de visite et qui ne sont pas de la division service seront ajoutés.',
        service: 'Seuls les leads ayant la division "Service" seront créés. Si la colonne division n\'est pas dans le fichier d\'import, la division "service" sera affectée aux leads.<br/><br/>Au moins un champ propriétaire doit être rempli. (conseiller, agent de service ou agent BDC).<br/>Si aucun propriétaire ne peut être déterminé pour un lead, le rendez-vous ne sera pas transféré dans NioText.',
        preBooking: 'Tous les leads seront créés avec la division "Service", même si la colonne division est présente dans le fichier d\'import.',
        processedSuccessfully: 'Import en cours...',
        updatedSuccessfully: "L'import a été mis à jour avec succès.",
    },
    deleteModal: 'Voulez-vous vraiment supprimer cet import ?',
    deleteValidatedModal: 'Voulez-vous vraiment supprimer cet import validé ?',
    eventEndDate: "Date de fin de l'événement",
    eventStartDate: "Date de début de l'événement",
    fieldInDatabase: 'Champs Activix CRM',
    fieldInFile: 'Champs dans le ficher',
    fieldInfoModal: {
        description: "Les champs qui ne sont pas présents dans ce tableau acceptent n'importe quel format",
        fieldInfo: 'Information',
        fieldName: 'Nom de champs',
        title: 'Liste des champs à importer',
    },
    fields: {
        address1: 'Adresse - ligne 1',
        address2: 'Adresse - ligne 2',
        appointment: 'Rendez-vous',
        appointment_date: 'Date de rendez-vous',
        approved: 'Approuvé',
        appt_confirmation: 'Rendez-vous confirmé',
        appt_confirmation_date: 'Date de confirmation du rendez-vous',
        appt_call: 'Rendez-vous téléphonique',
        appt_call_date: 'Date de rendez-vous téléphonique',
        available_date: 'Date véh. ici le',
        'bdc_user:': 'Agent BDC',
        be_back: 'Be back (Oui / Non)',
        be_back_date: 'Date du b-back',
        birth_date: 'Date de naissance',
        business: 'Affaires',
        call_date: "Date de l'appel",
        city: 'Ville',
        civility: 'Civilité',
        code: 'Code',
        casl_consent: 'Adhésion',
        business_name: "Nom d'entreprise",
        comment: 'Remarque / Commentaire',
        'commercial:': 'Directeur commercial',
        country: 'Pays',
        created_at: 'Date de création',
        csi: 'CSI',
        csi_date: 'Date de CSI',
        sector: 'Secteur',
        budget: 'Budget',
        communication_preference: 'Préférence de communication',
        dealer_tour: 'Dealer tour',
        delivered: '@.capitalize:lexicon.delivered.singular (Oui / Non)',
        'delivered_by:': '@.capitalize:lexicon.delivered.singular par',
        delivered_date: 'Date de @:lexicon.delivery.singular',
        deliverable_date: 'Date @:lexicon.deliverable.singular',
        delivery_date: 'Date @:lexicon.deliveryDate.singular',
        deposit: 'Dépôt',
        do_not_market: 'Do Not Market',
        write_up: 'Quotation',
        discounted_date: 'Date escomptée',
        'division:name': 'Division (Neuf / Occasion)',
        event: 'Événement',
        'exchange_vehicles:allowed_mileage': 'Km. alloués',
        'exchange_vehicles:balance': 'Solde',
        'exchange_vehicles:body_type': 'Carrosserie',
        'exchange_vehicles:bank:name': 'Institution',
        'exchange_vehicles:category': 'Catégorie',
        'exchange_vehicles:category_rv': 'Catégorie VR',
        'exchange_vehicles:color_exterior': 'Couleur',
        'exchange_vehicles:condition': 'Condition',
        'exchange_vehicles:end_contract_date': 'Fin de contrat',
        'exchange_vehicles:end_warranty_date': 'Fin de garantie',
        'exchange_vehicles:frequency': 'Fréquence',
        'exchange_vehicles:fuel': 'Carburant',
        'exchange_vehicles:initial_cash': 'Comptant initial',
        'exchange_vehicles:in_service_date': 'Mise en service',
        'exchange_vehicles:intention': 'Intention',
        'exchange_vehicles:length_max': 'Longueur max.',
        'exchange_vehicles:length_min': 'Longueur min.',
        'exchange_vehicles:link': 'Lien',
        'exchange_vehicles:make': 'Marque',
        'exchange_vehicles:mileage': 'Odomètre',
        'exchange_vehicles:mileage_excess': 'Odo. excédentaire estimé',
        'exchange_vehicles:modality': 'Modalité',
        'exchange_vehicles:model': 'Modèle',
        'exchange_vehicles:offer': '# Offre',
        'exchange_vehicles:option': 'Option',
        'exchange_vehicles:payment': 'Paiement',
        'exchange_vehicles:payment_with_tax': 'Paiement avec taxes',
        'exchange_vehicles:price': 'Prix',
        'exchange_vehicles:rate': 'Taux',
        'exchange_vehicles:requested': 'Souhaité',
        'exchange_vehicles:residual': 'Résiduel',
        'exchange_vehicles:security_deposit': 'Dépôt de sécurité',
        'exchange_vehicles:sleeping': 'Couchage',
        'exchange_vehicles:sold_by': '@.capitalize:lexicon.sold.singular par',
        'exchange_vehicles:sold_date': 'Date de @:lexicon.sale.singular',
        'exchange_vehicles:stock': '# Stock',
        'exchange_vehicles:term': 'Terme',
        'exchange_vehicles:transmission': 'Transmission',
        'exchange_vehicles:trim': 'Version (Trim)',
        'exchange_vehicles:value': 'Valeur',
        'exchange_vehicles:vin': 'NIV',
        'exchange_vehicles:warranty': 'Garantie',
        'exchange_vehicles:weight': 'Poids',
        'exchange_vehicles:year': 'Année',
        'exchange_vehicles:accidented_damage_cost': 'Accident $',
        'exchange_vehicles:tire_type': 'Pneus',
        first_name: 'Prénom',
        form: 'Formulaire',
        gas: 'Ravitaillé',
        imported_campaign: 'Campagne',
        inspected: 'Inspecté',
        keyword: '@:clientCard.keyword',
        last_name: 'Nom (Nom complet si un seul champs)',
        last_presented_date: 'Dernière visite',
        latest_comment: 'Notes',
        'lead_bank:name': 'Institution',
        'lead_emails:home': 'Courriel personnel',
        'lead_emails:work': 'Courriel travail',
        'lead_phones:cell': 'Téléphone cellulaire',
        'lead_phones:home': 'Téléphone maison',
        'lead_phones:work': 'Téléphone travail',
        'lead_phones:work_extension': 'Téléphone travail extension',
        locale: 'Langue (fr/en) (Défaut : fr)',
        lost_reason: 'Raison de la perte',
        loyalty: 'Loyauté',
        navigation_history: '@:clientCard.navigationHistory',
        next_presented_date: 'Prochaine relance',
        non_consumer_initiated: 'Non généré par le consommateur',
        odometer_last_visit: 'Odomètre dernière visite',
        open_work_order_date: 'Ouverture W.O.',
        performance: 'Performance',
        postal_code: {
            CA: 'Code postal',
            US: 'Code postal',
        },
        prepaid: 'Prépayé',
        prepared: 'Préparé',
        prepared_work_order: 'P.W.O.',
        presented: 'Visite (Oui / Non)',
        presented_date: 'Date de visite',
        paperwork_date: 'Date de dossier',
        progress_state: 'État',
        province: 'Province',
        qualification: 'Qualification',
        rating: 'Évaluation',
        rating_comment: "Commentaires d'évaluation",
        reached_client: 'Client rejoint',
        referrer: '@:clientCard.referrer',
        refinanced: 'Refinancé',
        refinanced_date: 'Date de refinancement',
        related_Id: 'ID du DMS',
        repair_date: 'Date de réparation',
        repair_order: 'Repair order #',
        result: 'Résultat',
        road_test: 'Test drive (Oui / Non)',
        road_test_date: 'Date de test drive',
        sale: 'Vente (Oui / Non)',
        sale_by_phone: 'Vente par tél.',
        sale_date: 'Date de vente',
        search_term: '@:clientCard.searchTerm',
        second_contact: 'Deuxième contact',
        second_contact_civility: 'Civilité deuxième contact',
        'secondary_user:': '@.capitalize:lexicon.secondaryAdvisor.singular',
        segment: 'Segment',
        'service_advisor:': 'Aviseur',
        'service_agent:': 'Agent de service',
        service_cleaning_car: 'Service lavage',
        service_interval_km: 'Service intervalle KM',
        service_monthly_km: 'Service mensuel KM',
        service_process_intention: 'Intention',
        service_process_transport: 'Transport',
        service_process_walk_around: 'Walk around',
        sex: 'Genre',
        sid: 'ID du CRM',
        source: 'Source',
        status: 'Status',
        storage: 'Entreposage',
        take_over: 'Take over (Oui / Non)',
        take_over_date: 'Date de take over',
        'take_over_director:': 'T.O. par',
        time_frame: 'Délai d\'intention d\'achat',
        twenty_four_hour: '24h',
        unsubscribe_call_date: 'Désabonné Appels',
        unsubscribe_email_date: 'Désabonné Courriels',
        unsubscribe_sms_date: 'Désabonné SMS',
        unsubscribe_all_date: 'NPD.',
        updated_at: 'Date de modification',
        'user:': 'Conseiller',
        walk_around: 'Walk around',
        'wanted_vehicles:accessories': 'Accessoires',
        'wanted_vehicles:budget_max': 'Budget max.',
        'wanted_vehicles:budget_min': 'Budget min.',
        'wanted_vehicles:body_type': 'Carrosserie',
        'wanted_vehicles:category': 'Catégorie',
        'wanted_vehicles:category_rv': 'Catégorie VR',
        'wanted_vehicles:color_exterior': 'Couleur',
        'wanted_vehicles:documentation': 'Documentation',
        'wanted_vehicles:frequency': 'Fréquence',
        'wanted_vehicles:fuel': 'Carburant',
        'wanted_vehicles:end_warranty_date': 'Fin de garantie',
        'wanted_vehicles:end_contract_date': 'Fin de contrat',
        'wanted_vehicles:initial_cash': 'Comptant initial',
        'wanted_vehicles:in_service_date': 'Mise en service',
        'wanted_vehicles:length_max': 'Longueur max.',
        'wanted_vehicles:length_min': 'Longueur min.',
        'wanted_vehicles:link': 'Lien',
        'wanted_vehicles:make': 'Marque',
        'wanted_vehicles:modality': 'Modalité',
        'wanted_vehicles:model': 'Modèle',
        'wanted_vehicles:offer': '# Offre',
        'wanted_vehicles:order_number': '# comm.',
        'wanted_vehicles:payment': 'Paiement',
        'wanted_vehicles:payment_with_tax': 'Paiement avec taxes',
        'wanted_vehicles:preparation': 'Préparation',
        'wanted_vehicles:price': 'Prix',
        'wanted_vehicles:profit': 'Profit',
        'wanted_vehicles:rate': 'Taux',
        'wanted_vehicles:recorded_date': 'Enregistré le',
        'wanted_vehicles:sleeping': 'Couchage',
        'wanted_vehicles:sold': '@.capitalize:lexicon.sold.singular',
        'wanted_vehicles:stock': '# Stock',
        'wanted_vehicles:stock_state': 'État de stock',
        'wanted_vehicles:suffix': 'Suffix',
        'wanted_vehicles:term': 'Terme',
        'wanted_vehicles:tire': 'Pneus $',
        'wanted_vehicles:transmission': 'Transmission',
        'wanted_vehicles:trim': 'Version (Trim)',
        'wanted_vehicles:vin': 'NIV',
        'wanted_vehicles:weight': 'Poids',
        'wanted_vehicles:year': 'Année',
        'wanted_vehicles:year_max': 'Année max.',
        'wanted_vehicles:year_min': 'Année min.',
        work_order: 'W.O. #',
        work_order_closure_date: '@:clientCard.workOrderClosureDate',
        work_order_partial_closure_date: '@:clientCard.workOrderPartialClosureDate',
    },
    importRule: "Règle d'importation",
    importRules: {
        merge: 'Fusionner',
        create: 'Créer',
    },
    leadType: 'Type de lead à mettre par défaut',
    needSidModal: {
        title: 'Le champs "ID du CRM" est vide',
        description: 'Les leads événements ne seront pas associés aux leads originaux',
    },
    eventDifferentDatesModal: {
        title: "Dates d'événement différentes",
        description:
            'Les dates de début et fin de campagnes entrées remplaceront les précédentes:<br><br>Début: {0}<br>Fin: {1}',
    },
    revision: 'Révision',
    sections: {
        exchange_vehicle: 'Véhicule actuel',
        general: 'Général',
        performance: 'Performance',
        personal: 'Informations personnelles',
        process: 'Processus',
        revival_service: 'Relance',
        wanted_vehicle: 'Véhicule recherché',
        customFields: 'Champ personnalisé',
        service: 'Services vendus',
        service_process: 'Processus de Service',
    },
    sectionsInfo: {
        revivalService: 'Utilisé seulement dans la section service',
    },
    segments: {
        conquest: 'Conquête',
        loyalty: 'Loyauté',
        notSold: 'Non @:lexicon.sold.singular',
        other: 'Autre',
        promo: 'Promotion',
        service: 'Service',
        reminder: 'Rappel',
        endWarranty: 'Fin garantie',
        endLcap: 'Fin LCAP',
        endLnette: 'Fin LNETTE',
        nowShow: 'No show',
        csi: 'CSI',
    },
    subTitle: 'Veuillez spécifier à quels champs assigner vos données',
    table: {
        headings: {
            account: 'Compte',
            originalFile: 'Fichier original',
            leadType: 'Type de lead',
            importedBy: 'Importé par',
            qtyInFile: 'À importer',
            qtyProcessed: 'Traités',
            created: 'Créés',
            qtyMerged: 'Fusionnés',
            createdAt: 'Créé à',
            startedAt: 'Commencé à',
            finishedAt: 'Terminé à',
            progress: 'Progression',
        },
        filter: 'Filtrer',
        noResults: 'Aucun résultat trouvé',
        limit: 'Éléments :',
        count: 'Éléments',
        title: 'Dernières importations',
    },
    tooltips: {
        eventEndDate:
            "La date de fin de l'événement est obligatoire pour les 30 jours de synchronisation avec les lead originaux",
        importRule:
            'L\'option "Fusionner" fusionnera les leads déjà existant dans votre compte selon vos préférences (Compléter ou Remplacer).\n\nL\'option "Créer" ajoutera tous leads en associant les leads correspondant.\n\n* N.B. L\'import n\'apportera aucune modification au type de lead déjà existant.',
        sex: 'En cas de discordance entre la civilité et le genre, le genre sera déterminé depuis la civilité',
    },
    upload: {
        button: 'Prochaine étape',
        delimiter: 'Délimiteur',
        selectFile: 'Sélectionnez le fichier CSV à importer',
    },
    unselectedFieldsModal: {
        button: 'Importer',
        description: 'Certains champs de votre fichier CSV ne sont pas séléctionnés dans votre import.',
        fieldName: 'Nom de champs',
        title: 'Liste des champs qui ne sont pas importés',
    },
    validateModal: {
        button: 'Valider',
        description: "Ceci entraînera la fusion et l'association de certains leads.",
        title: 'Êtes-vous sûr de vouloir valider cet import?',
    },
    allowEmptyCreatedAt: {
        button: 'Utiliser la date d\'aujourd\'hui comme date de création',
        description: 'Êtes-vous certain de vouloir mettre la date d\'aujourd\'hui comme date de création du lead ?',
    },
};
