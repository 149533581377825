import { getFullName } from '@/utils';
import ActivixDate from '@/value-objects/ActivixDate';

import Lead from '@/entities/Lead.js';
import Model from '@/entities/Model.js';
import Reminder from '@/entities/Reminder.js';
import TaskEventType from '@/entities/TaskEventType.js';
import TaskEventTypeIcon from '@/entities/TaskEventTypeIcon.js';
import User from '@/entities/User.js';

export default class TaskEvent extends Model {
    static get defaults() {
        return {
            address: null,
            automation_action: {},
            canceled: false,
            completed_at: null,
            confirmed: false,
            customer_virtual_event_link: null,
            description: '',
            end_at: null,
            guests: [],
            lead: {},
            location: null,
            main_event: false,
            no_show: false,
            priority: 'normal',
            reminders: [],
            start_at: null,
            status: false,
            task_event_type_id: null,
            title: '',
            user: {},
            user_id: null,
            user_virtual_event_link: null,
            confirmation_timeframe: null,
            send_ics: false,
            wanted_vehicle_ids: null,
        };
    }

    /**
     * Getters
     */

    get lead() {
        return this._lead;
    }

    get user() {
        return this._user;
    }

    get guests() {
        return this._guests;
    }

    get reminders() {
        return this._reminders;
    }

    get startAt() {
        return new ActivixDate(this.start_at);
    }

    /**
     * Setters
     */

    set lead(lead) {
        this._lead = new Lead(lead);
    }

    set user(user) {
        this._user = new User(user);
    }

    set guests(guests) {
        this._guests = guests
            .map(guest => new User(guest))
            .sort((a, b) => {
                return a.fullName.localeCompare(b.fullName);
            });
    }

    set reminders(reminders) {
        this._reminders = reminders.map(reminder => new Reminder(reminder));
    }

    /**
     * Specifications
     */

    get hasStatus() {
        return this.canceled || this.no_show || this.status;
    }

    get icon() {
        const taskEventType = TaskEventType.getKey(this.task_event_type_id);

        return TaskEventTypeIcon.entries[taskEventType];
    }

    get iconName() {
        return TaskEventType.getIconName(this.task_event_type_id);
    }

    get isLate() {
        if (this.startAt.isFuture()) {
            return false;
        }

        return !this.hasStatus;
    }

    get isPlanned() {
        return !this.hasStatus;
    }

    get ownerFullName() {
        return getFullName(this.user);
    }

    get isTodayOrFutureVirtualAppointment() {
        return this.task_event_type_id === TaskEventType.VIRTUAL_APPOINTMENT &&
            (new ActivixDate(this.start_at)).isSameOrAfter(new ActivixDate('now').startOfDay());
    }
}
