/* eslint-disable camelcase */
import { get, isArray, uniqWith } from 'lodash-es';

import { findContextAccount } from '@/pinia/storeHelper.js';
import Role from '@/entities/Role.js';
import Account from './Account.js';
import CommunicationMethod from './CommunicationMethod.js';
import CommunicationStatus from './CommunicationStatus.js';
import CommunicationType from './CommunicationType.js';
import Customer from './Customer.js';
import Dashboard from './Dashboard.js';
import Division from './Division.js';
import LeadEmail from './LeadEmail.js';
import LeadEmailType from './LeadEmailType.js';
import LeadPhone from './LeadPhone.js';
import LeadPhoneType from './LeadPhoneType.js';
import LeadResult from './LeadResult.js';
import LeadStatus from './LeadStatus.js';
import LeadType from './LeadType.js';
import LeadVehicle from './LeadVehicle.js';
import LeadVehicleType from './LeadVehicleType.js';
import Model from './Model.js';
import ProgressState from './ProgressState.js';
import SaleState from './SaleState.js';
import TaskEventType from './TaskEventType.js';
import User from './User.js';

import ActivixDate from '../value-objects/ActivixDate.js';
import ClientCardSection from './ClientCardSection.js';

export default class Lead extends Model {
    static get defaults() {
        return {
            // IDs
            bdc_user_id: null,
            commercial_id: null,
            division_id: null,
            lead_type_id: null,
            second_bdc_user_id: null,
            second_user_id: null,
            secondary_user_id: null,
            service_advisor_id: null,
            service_agent_id: null,
            user_id: null,
            created_by: null,
            updated_by: null,

            // Fields
            gas: false,
            unsubscribe_all_date: null,

            // Relationships (BelongsTo)
            account: {},
            customer: {},
            lead_type: {},
            creator: {},
            updater: {},

            // Relationships (HasMany)
            automation_jobs: [],
            renewal_alert_notifications: [],
            custom_fields: [],
            communications: [],
            emails: [],
            phones: [],
            products: [],
            task_events: [],
            vehicles: [],
            campaigns: [],
        };
    }

    init(values) {
        delete values.sold_or_first_wanted_vehicle;

        super.init(values);
    }

    /**
     * Private
     */

    _extractStoreUser(attribute, property) {
        const currentUser = this.load(attribute);
        const alreadyLoaded = currentUser && currentUser.id == this[property];

        if (alreadyLoaded) {
            return currentUser;
        }

        if (this[property] !== null && this.account.users) {
            const user = this.account.users.find(user => user.id == this[property]);

            if (user) {
                this.store(attribute, new User(user));
                return user;
            }
        }

        return new User();
    }

    _getUserOrSupplierUser(property) {
        const user = this.account.activeUsersAndSupplierUsers.find(user => {
            return user.id == this[property] || user.parent_user_id == this[property];
        });

        return (
            user ||
            new User({
                first_name: 'Activix',
                last_name: 'CRM',
            })
        );
    }

    /**
     * Getters
     */
    get account() {
        if (this.loaded('account')) {
            return this.load('account');
        }

        if (!this.relationLoaded('account') && this.account_id) {
            this._account = findContextAccount(this.account_id) || new Account(this._account);
        }

        this.store('account', this._account);
        return this._account;
    }

    get customer() {
        return this._customer;
    }

    get lead_phones() {
        return this.phones;
    }

    get lead_emails() {
        return this.emails;
    }

    get fullName() {
        if (this.business && this.business_name) {
            return this.business_name.trim();
        }

        return [this.first_name, this.last_name].join(' ').trim();
    }

    get shortName() {
        if (this.business && this.business_name) {
            return this.business_name.trim();
        }

        return [this.first_name.charAt(0), this.last_name].join('. ').trim();
    }

    get user() {
        return this._extractStoreUser('user', 'user_id');
    }

    get creator() {
        return this._getUserOrSupplierUser('created_by');
    }

    get updater() {
        return this._getUserOrSupplierUser('updated_by');
    }

    get bdc_user() {
        return this._extractStoreUser('bdcUser', 'bdc_user_id');
    }

    get commercial() {
        return this._extractStoreUser('commercial', 'commercial_id');
    }

    get delivered_by() {
        return this._extractStoreUser('deliveredBy', 'delivered_by_id');
    }

    get take_over_director() {
        return this._extractStoreUser('takeOverDirector', 'take_over_director_id');
    }

    get status_updated_by() {
        return this._extractStoreUser('statusUpdatedBy', 'status_updated_by_id');
    }

    get second_user() {
        return this._extractStoreUser('secondUser', 'second_user_id');
    }

    get secondary_user() {
        return this._extractStoreUser('secondaryUser', 'secondary_user_id');
    }

    get second_bdc_user() {
        return this._extractStoreUser('secondBdcUser', 'second_bdc_user_id');
    }

    get service_advisor_user() {
        return this._extractStoreUser('serviceAdvisor', 'service_advisor_id');
    }

    get service_agent_user() {
        return this._extractStoreUser('serviceAgent', 'service_agent_id');
    }

    get service_advisor() {
        return this._extractStoreUser('serviceAdvisor', 'service_advisor_id');
    }

    get service_agent() {
        return this._extractStoreUser('serviceAgent', 'service_agent_id');
    }

    get exchange_vehicles() {
        return this.vehicles.filter(vehicle => vehicle?.type == LeadVehicleType.EXCHANGE);
    }

    get wanted_vehicles() {
        return this.vehicles.filter(vehicle => vehicle?.type == LeadVehicleType.WANTED);
    }

    get sold_or_first_wanted_vehicle() {
        return this.getSoldOrFirstWantedVehicle();
    }

    get renewalVehicle() {
        return this.exchange_vehicles[0];
    }

    get serviceVehicle() {
        return this.exchange_vehicles.find(vehicle => vehicle.service) || this.exchange_vehicles[0];
    }

    get financialDataFreezed() {
        return this.freeze_financial_data && this.account.commercial;
    }

    get cellPhone() {
        return this.phones.find(phone => {
            return phone.type === LeadPhoneType.CELL;
        });
    }

    get homeEmail() {
        return this.emails.find(email => {
            return email.type === LeadEmailType.HOME;
        });
    }

    get homePhone() {
        return this.phones.find(phone => {
            return phone.type === LeadPhoneType.HOME;
        });
    }

    get workEmail() {
        return this.emails.find(email => {
            return email.type === LeadEmailType.WORK;
        });
    }

    get workPhone() {
        return this.phones.find(phone => {
            return phone.type === LeadPhoneType.WORK;
        });
    }

    get hasValidEmail() {
        return this.lead_emails.some(email => email.valid);
    }

    get isDuplicate() {
        return this.status === LeadStatus.DUPLICATE;
    }

    get isInvalid() {
        return this.status === LeadStatus.INVALID;
    }

    get isLost() {
        return this.status === LeadStatus.LOST;
    }

    get statusIcon() {
        return LeadStatus.getIcon(this.status);
    }

    get typeIcon() {
        return LeadType.getIcon(this.lead_type_id);
    }

    get contextDate() {
        let date = null;

        if (this.isService()) {
            date = this.appointment_date;
        } else {
            switch (this.lead_type_id) {
                case LeadType.PHONE:
                    date = this.call_date;
                    break;
                case LeadType.WALK_IN:
                    date = this.presented_date;
                    break;
                case LeadType.RENEWAL:
                    date = this.end_contract_date;
                    break;
            }
        }

        return new ActivixDate(date || this.created_at);
    }

    /**
     * Setters
     */

    set account(account) {
        this._account = account;
    }

    set customer(customer) {
        if (this.relationLoaded('customer') && empty(customer)) {
            return;
        }

        this._customer = new Customer(customer);
    }

    set client(client) {
        if (this.relationLoaded('customer')) {
            return;
        }

        this._customer = new Customer(client);
    }

    set lead_phones(newPhones) {
        this.phones = newPhones;
    }

    set lead_emails(newEmails) {
        this.emails = newEmails;
    }

    set user(user) {
        //
    }

    set creator(user) {
        //
    }

    set updater(user) {
        //
    }

    set bdc_user(user) {
        //
    }

    set commercial(user) {
        //
    }

    set delivered_by(user) {
        //
    }

    set take_over_director(user) {
        //
    }

    set status_updated_by(user) {
        //
    }

    set secondary_user(user) {
        //
    }

    set second_user(user) {
        //
    }

    set second_bdc_user(user) {
        //
    }

    set service_advisor_user(user) {
        //
    }

    set service_agent_user(user) {
        //
    }

    set service_advisor(user) {
        //
    }

    set service_agent(user) {
        //
    }

    set wanted_vehicles(vehicles) {
        this.vehicles = this.vehicles
            .filter(vehicle => {
                return vehicle.type != LeadVehicleType.WANTED;
            })
            .concat(vehicles);
    }

    set exchange_vehicles(vehicles) {
        this.vehicles = this.vehicles
            .filter(vehicle => {
                return vehicle.type != LeadVehicleType.EXCHANGE;
            })
            .concat(vehicles);
    }

    /**
     * Specifications
     */

    isAssigned() {
        return !!this.user_id || !!this.secondary_user_id || !!this.bdc_user_id;
    }

    isNotAssigned() {
        return !this.isAssigned();
    }

    isAssignedTo(userIds, extended = false) {
        if (!isArray(userIds)) {
            userIds = [userIds];
        }

        if (!extended) {
            return userIds.includes(this.user_id) ||
                userIds.includes(this.secondary_user_id) ||
                userIds.includes(this.bdc_user_id);
        }

        return userIds.includes(this.user_id) ||
            userIds.includes(this.secondary_user_id) ||
            userIds.includes(this.bdc_user_id) ||
            userIds.includes(this.commercial_id) ||
            userIds.includes(this.service_advisor_id) ||
            userIds.includes(this.service_agent_id);
    }

    isNotAssignedTo(userIds, extended = false) {
        return !this.isAssignedTo(userIds, extended);
    }

    isOfType(types) {
        if (!isArray(types)) {
            types = [types];
        }

        return types.includes(this.lead_type_id);
    }

    isNotOfType(types) {
        return !this.isOfType(types);
    }

    isNew() {
        return this.division_id === Division.NEW;
    }

    isUsed() {
        return this.division_id === Division.USED;
    }

    isService() {
        return this.division_id === Division.SERVICE;
    }

    isFinance() {
        return this.division_id === Division.FINANCE;
    }

    isSold() {
        return !!this.sale_date;
    }

    isDelivered() {
        return !!this.delivered_date;
    }

    wasPresentedBetween(startDate, endDate) {
        return new ActivixDate(this.presented_date).isBetween(startDate, endDate);
    }

    wasBeBackBetween(startDate, endDate) {
        return new ActivixDate(this.be_back_date).isBetween(startDate, endDate);
    }

    wasSoldBetween(startDate, endDate) {
        return new ActivixDate(this.sale_date, 'date').isBetween(startDate, endDate);
    }

    wasBoughtBack() {
        return this.isOfType(LeadType.RENEWAL) && !!this.refinanced_date;
    }

    getUserName() {
        return [(get(this.user, 'first_name') || '').trim(), (get(this.user, 'last_name') || '').trim()].join(' ');
    }

    getAccountName() {
        return (get(this.account, 'name') || '-').trim();
    }

    getMobilePhones() {
        return (this.phones || []).filter(p => p.mobile && p.validated);
    }

    getUniquePhones() {
        return uniqWith(this.phones || [], (a, b) => {
            return !a.valid || a.type === b.type && a.phone === b.phone;
        });
    }

    getAssignedUserEmails() {
        const emails = [];

        if (this.user) {
            emails.push({
                email: this.user.email,
                role: 'user',
            });
        }

        if (this.bdc_user.email) {
            emails.push({
                email: this.bdc_user.email,
                role: 'bcd_user',
            });
        }

        if (this.commercial.email) {
            emails.push({
                email: this.commercial.email,
                role: 'commercial',
            });
        }

        return emails;
    }

    getEmailsString() {
        return (this.emails || [])
            .map(e => {
                return new LeadEmail(e).toString();
            })
            .join('<br>');
    }

    getPhonesString() {
        return (this.phones || [])
            .map(p => {
                return new LeadPhone(p).toString();
            })
            .join('<br>');
    }

    getVehiclesString(type = null) {
        const vehicleRelation = LeadVehicle.validType(type) ? type : 'lead';

        return (this[`${vehicleRelation}_vehicles`] || [])
            .map(v => {
                return new LeadVehicle(v).toString();
            })
            .join('<br>');
    }

    getVehiclesField(field, type = null) {
        const vehicleRelation = LeadVehicle.validType(type) ? type : 'lead';

        return (this[`${vehicleRelation}_vehicles`] || []).map(v => v[field]).join('<br>');
    }

    getSoldVehicle() {
        return this.wanted_vehicles.find(v => v.sold);
    }

    getSoldOrFirstWantedVehicle() {
        return this.getSoldVehicle() || this.wanted_vehicles[0];
    }

    hasVerifiedSoldVehicle() {
        return this.wanted_vehicles.some(v => v.sold && v.verified_by_id);
    }

    isSoldOrRefinanced() {
        return !!(this.sale_date || this.sale_by_phone || this.refinanced_date);
    }

    isRefinanced() {
        return this.isOfType(LeadType.RENEWAL) && !!this.refinanced_date;
    }

    isRenewed() {
        return this.isOfType(LeadType.RENEWAL) && !!this.sale_date;
    }

    isValidForAutoRenew() {
        const soldVehicle = this.getSoldVehicle();

        if (!soldVehicle) {
            return false;
        }

        return !!soldVehicle.end_contract_date || soldVehicle.modality == 'cash';
    }

    canAutoRenew() {
        return (
            this.account.auto_renewal &&
            (![1, 2].includes(this.division_id) ||
                (this.isNew() && this.account.auto_renewal_new) ||
                (this.isUsed() && this.account.auto_renewal_used))
        );
    }

    getSaleState(start, end, carryOverOption) {
        start = ActivixDate.parseDate(start, 'start');
        end = ActivixDate.parseDate(end, 'end');

        const isDiscounted = this.account.client_card_fields.process.discounted && this.discounted_date;

        if (this.isForcedWaitingSale()) {
            return SaleState.WAITING_SALE;
        }

        if (this.status == 'lost') {
            return SaleState.LOST;
        }

        if (this.progress_state == ProgressState.REFUSED) {
            return SaleState.REFUSED;
        }

        const deliveryDate = new ActivixDate(this.delivery_date);
        const deliveredDate = new ActivixDate(this.delivered_date);

        if (carryOverOption && this.isCarryOver(start, end)) {
            if (this.delivered_date) {
                if (isDiscounted) {
                    return SaleState.DISCOUNTED;
                }

                return SaleState.CARRY_OVER;
            }

            if (this.delivery_date || this.deliverable_date) {
                return SaleState.DELIVERY_CARRY_OVER;
            }

            return SaleState.CARRY_OVER_NOT_DELIVERED;
        }

        if (this.isWaitingSale(start, end)) {
            return SaleState.WAITING_SALE;
        }

        if ((!this.delivered_date && deliveryDate.isAfter(end)) || deliveredDate.isAfter(end)) {
            return SaleState.POSTPONED;
        }

        if (!this.delivered_date && deliveryDate.isBetween(start, end)) {
            return SaleState.PLANNED;
        }

        if (!this.delivery_date) {
            if (this.approved) {
                return SaleState.APPROVED;
            }

            return SaleState.SOLD;
        }

        if (this.delivered_date) {
            if (isDiscounted) {
                return SaleState.DISCOUNTED;
            }

            return SaleState.DELIVERED;
        }

        return SaleState.NONE;
    }

    isCarryOver(start, end) {
        start = ActivixDate.parseDate(start, 'start');
        end = ActivixDate.parseDate(end, 'end');

        if (!this.sale_date || this.status == 'lost' || this.progress_state == ProgressState.REFUSED) {
            return false;
        }

        const carryOverRange = Dashboard.getCarryOverRange(start, end);
        const saleDate = new ActivixDate(this.sale_date, 'date');
        const deliveryDate = new ActivixDate(this.delivery_date);
        const deliveredDate = new ActivixDate(this.delivered_date);
        const deliverableDate = new ActivixDate(this.deliverable_date);

        return (
            saleDate.isBefore(start) &&
            (
                deliveryDate.isBetween(start, end) ||
                deliveredDate.isBetween(start, end) ||
                deliverableDate.isBetween(start, end) ||
                !this.delivery_date && !this.deliverable_date && saleDate.isBetween(carryOverRange.start, carryOverRange.end)

            )
        );
    }

    isUnsubscribedToAll() {
        return !!this.unsubscribe_all_date && new ActivixDate(this.unsubscribe_all_date).isFuture();
    }

    isUnsubscribedToEmail() {
        return this.isUnsubscribedToAll() || !!this.unsubscribe_email_date;
    }

    isUnsubscribedToCall() {
        return this.isUnsubscribedToAll() || !!this.unsubscribe_call_date;
    }

    isUnsubscribedToSms() {
        return this.isUnsubscribedToAll() || !!this.unsubscribe_sms_date;
    }

    isWaitingSale(start, end) {
        start = ActivixDate.parseDate(start, 'start');
        end = ActivixDate.parseDate(end, 'end');

        if (this.isForcedWaitingSale()) {
            return true;
        }

        const deliveryDate = new ActivixDate(this.delivery_date, 'date');
        const deliverableDate = new ActivixDate(this.deliverable_date, 'date');

        if (
            !this.account.enabledWaitingSale() ||
            this.status == 'lost' ||
            this.progress_state == ProgressState.REFUSED ||
            !this.sale_date ||
            this.delivered_date ||
            deliveryDate.isBetween(start, end) ||
            deliverableDate.isBetween(start, end)
        ) {
            return false;
        }

        const waitingSaleDate = new ActivixDate(this.account.waiting_sale_date, 'date');
        const saleDate = new ActivixDate(this.sale_date, 'date');

        return saleDate.isBefore(start) && saleDate.isSameOrAfter(waitingSaleDate);
    }

    isForcedWaitingSale() {
        return this.account.enabledWaitingSale() && this.waiting_sale && !this.delivered_date;
    }

    isCreatedBeforeServiceReachedRelease() {
        const serviceReleaseDate = new ActivixDate('2021-08-17 00:00');
        const leadCreationDate = new ActivixDate(this.created_at);

        return leadCreationDate.isBefore(serviceReleaseDate);
    }

    getResult() {
        if (
            this.isCreatedBeforeServiceReachedRelease() && this.division_id == Division.SERVICE && (this.appointment_date || this.appt_call_date) ||
            this.presented_date ||
            this.home_presented_date ||
            this.sale_date ||
            this.delivered_date
        ) {
            return LeadResult.REACHED;
        }

        if (this.communications.length) {
            let reached = false;
            let attempted = false;

            for (const communication of this.communications) {
                // If comunication more than 60 seconds
                if (
                    !!communication.url &&
                    communication.duration_call >= 60 &&
                    (!this.result || this.result == LeadResult.REACHED)
                ) {
                    return LeadResult.REACHED;
                }

                // If incoming communication
                if (
                    communication.communication_type_id == CommunicationType.INCOMING &&
                    (communication.communication_method_id == CommunicationMethod.EMAIL ||
                        communication.created_by_user)
                ) {
                    return LeadResult.REACHED;
                }

                // If created or updated by user and set to answered
                if (
                    communication.status == CommunicationStatus.ANSWERED &&
                    (communication.created_by_user || communication.updated_by_user)
                ) {
                    return LeadResult.REACHED;
                }

                // If SMS communication
                if (
                    communication.communication_method_id == CommunicationMethod.SMS &&
                    communication.status == CommunicationStatus.ANSWERED
                ) {
                    return LeadResult.REACHED;
                }

                // If Messenger communication
                if (
                    communication.communication_method_id == CommunicationMethod.MESSENGER &&
                    (communication.exchange_counter > 0 || communication.outbound_counter > 0) &&
                    communication.status != CommunicationStatus.ATTEMPTED
                ) {
                    return LeadResult.REACHED;
                }

                if (
                    communication.communication_method_id == CommunicationMethod.VIDEO &&
                    [CommunicationStatus.CALLING, CommunicationStatus.ANSWERED].includes(communication.status)
                ) {
                    return LeadResult.REACHED;
                }

                // If answered communication or lead is reached or communication has appointment (deprecated)
                if (
                    communication.status == CommunicationStatus.ANSWERED ||
                    this.result == LeadResult.REACHED ||
                    (this.communications[0] && this.communications[0].appointment)
                ) {
                    reached = true;
                }

                if (
                    this.result == LeadResult.ATTEMPTED ||
                    (communication.affect_lead_status && communication.status == CommunicationStatus.ATTEMPTED)
                ) {
                    attempted = true;
                }
            }

            if (this.isOfType(LeadType.WALK_IN) && this.created_by_user) {
                return LeadResult.REACHED;
            }

            if (reached && (this.result != LeadResult.ATTEMPTED || this.isResultSet())) {
                return LeadResult.REACHED;
            }

            if (attempted) {
                return LeadResult.ATTEMPTED;
            }

            return LeadResult.PENDING;
        }

        if (this.result == LeadResult.REACHED) {
            return LeadResult.REACHED;
        }

        if (this.result == LeadResult.ATTEMPTED) {
            return LeadResult.ATTEMPTED;
        }

        return LeadResult.PENDING;
    }

    isResultSet() {
        if (this.id == 0) {
            return false;
        }

        if (
            this.isCreatedBeforeServiceReachedRelease() && this.division_id == Division.SERVICE && (this.appointment_date || this.appt_call_date) ||
            this.presented_date ||
            this.home_presented_date ||
            this.sale_date ||
            this.delivered_date ||
            this.road_test_date ||
            this.home_road_test_date ||
            this.be_back_date ||
            this.take_over_date
        ) {
            return true;
        }

        for (const communication of this.communications || []) {
            if (communication.communication_type_id == CommunicationType.INCOMING) {
                return true;
            }

            if (
                communication.communication_type_id == CommunicationType.OUTGOING &&
                communication.communication_method_id == CommunicationMethod.PHONE &&
                communication.status == CommunicationStatus.ANSWERED &&
                !communication.created_by_user
            ) {
                return true;
            }

            if (
                communication.communication_method_id == CommunicationMethod.SMS &&
                communication.status == CommunicationStatus.ANSWERED
            ) {
                return true;
            }
        }

        return false;
    }

    hasDeliveryMan(userId) {
        if (!userId) {
            return false;
        }

        return this.task_events.some(task => {
            return task.task_event_type_id == TaskEventType.DELIVERY && this.delivered_by_id == userId;
        });
    }

    hasInvalidStatus() {
        return [LeadStatus.INVALID, LeadStatus.LOST, LeadStatus.DUPLICATE].includes(this.status);
    }

    findUser(userId) {
        return this.account.users.find(user => user.id == userId);
    }

    fill(payload) {
        if (!payload) {
            return;
        }

        Object.keys(payload).forEach(key => {
            if (typeof this[key] !== 'undefined') {
                this[key] = payload[key];
            }
        });
    }

    fillCustomable(id, payload) {
        if (!payload) {
            return;
        }

        let leadVehicle = null;
        const value = typeof payload.value == 'string' ? [payload.value] : payload.value;
        let customField = this.custom_fields.find(customField => customField.id == id);

        if (payload.vehicleId) {
            leadVehicle = this.vehicles.find(leadVehicle => leadVehicle.id == payload.vehicleId);
            customField = leadVehicle?.custom_fields?.find(customField => customField.id == id);
        }

        if (customField) {
            customField.pivot.value = value;
        } else {
            const newCustomField = {
                id,
                name: payload.label,
                field: payload.field,
                pivot: {
                    value,
                },
            };

            if (leadVehicle) {
                leadVehicle.custom_fields.push(newCustomField);
            } else {
                this.custom_fields.push(newCustomField);
            }
        }
    }

    fillVehicle(id, payload) {
        const vehicle = this.vehicles.find(v => v.id == id);

        Object.keys(payload).forEach(key => {
            if (typeof vehicle[key] !== 'undefined') {
                vehicle[key] = payload[key];
            }
        });
    }

    getVehicleCustomFieldsById(customFieldId, clientCardSection = '') {
        let vehicleType = '';
        let vehicles = this.vehicles;

        if (ClientCardSection.isVehicleExchangeField(clientCardSection)) {
            vehicleType = LeadVehicleType.EXCHANGE;
        } else if (ClientCardSection.isVehicleWantedField(clientCardSection)) {
            vehicleType = LeadVehicleType.WANTED;
        }

        if (vehicleType) {
            vehicles = vehicles.filter(v => v.type === vehicleType);
        }

        if (vehicles.some(v => v.sold)) {
            vehicles = vehicles.filter(v => v.sold);
        }

        vehicles = vehicles.map(vehicle => {
            return { custom_fields: vehicle.custom_fields, vehicle };
        })
            .flat();

        return vehicles.filter(obj => obj?.custom_fields?.filter(customField => customField.id === customFieldId));
    }

    getCustomableById(customFieldId) {
        return this.custom_fields.find(customField => customField.id === customFieldId);
    }

    getCustomableVehicleById(customFieldId, clientCardSection) {
        const vehicles = this.getVehicleCustomFieldsById(customFieldId, clientCardSection);

        if (!vehicles.length) {
            return null;
        }

        return vehicles[0].custom_fields.find(customField => customField.id == customFieldId);
    }

    disabledBySaleVerified(authUser, leadVehicle = null) {
        if (leadVehicle) {
            return authUser.id &&
                this.account.verified_sale &&
                leadVehicle.type === LeadVehicleType.WANTED &&
                leadVehicle.verified_by_id &&
                !authUser.isAdmin() &&
                !Role.hasDirectorRights(authUser.role_id);
        }

        return authUser.id &&
            this.account.verified_sale &&
            !authUser.isAdmin() &&
            !Role.hasDirectorRights(authUser.role_id) &&
            this.hasVerifiedSoldVehicle();
    }
}
