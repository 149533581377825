/* eslint-disable camelcase */
import { snakeCase, isPlainObject, isEmpty } from 'lodash-es';
import { findContextAccount } from '@/pinia/storeHelper.js';
import can from '../plugins/can.js';
import i18n from '../plugins/vue-i18n.js';
import Model from './Model.js';
import Account from './Account.js';
import Division from './Division.js';
import Role from './Role.js';
import Session from './Session.js';
import UserPhone from './UserPhone.js';

export default class User extends Model {
    static get defaults() {
        return {
            account: {},
            module_access: {},
            children: [],
            confidentiality_agreement: true,
            custom_permissions: {},
            divisions: [],
            email_config: {},
            analytics_dashboards: [],
            groups: [],
            objectives: [],
            phones: [],
            timezone: 'America/Montreal',
            shared_dashboard_views: [],
        };
    }

    /**
     * Constants
     */
    static get NIOTEXT_USER() {
        return 25558;
    }

    /**
     * Getters
     */

    get account() {
        if (!this.relationLoaded('account') && this.account_id) {
            this._account = findContextAccount(this.account_id) || new Account(this._account);
        }

        return this._account;
    }

    get children() {
        if (this.loaded('children')) {
            return this.load('children');
        }

        const children = this._children
            .map(user => new User(user))
            .sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.store('children', children);

        return children;
    }

    get phones() {
        return this._phones;
    }

    get fullName() {
        return `${this.first_name || ''} ${this.last_name || ''}`.trim();
    }

    get shortName() {
        let name = this.fullName;
        const spacePosition = name.indexOf(' ');

        if (spacePosition !== -1) {
            name = `${name.charAt(0)}. ${name.substring(spacePosition + 1, name.length)}`;
        }

        return name;
    }

    get session() {
        return this._session;
    }

    get role() {
        const role = Role.getKey(this.role_id);

        if (!role) {
            return '-';
        }

        if (this.sex == 'W') {
            return i18n.t(`database.role_${role}_w`);
        }

        return i18n.t(`database.role_${role}`);
    }

    /**
     * Setters
     */

    set account(account) {
        this._account = account;
    }

    set children(children) {
        this._children = children;
    }

    set phones(phones) {
        this._phones = phones.map(phone => {
            return new UserPhone(phone);
        });
    }

    set role(role) {
        if (role?.id) {
            this.role_id = role.id;
        }
    }

    set session(session) {
        this._session = !isEmpty(session) ? new Session(session) : null;
    }

    /**
     * Specifications
     */

    hasDivision(divisionId) {
        return !divisionId || this.divisions.some(division => division.id == divisionId);
    }

    doesNotHaveDivision(divisionId) {
        return !this.hasDivision(divisionId);
    }

    hasDivisionAccess(divisionId) {
        return this.isAdmin() || this.hasDivision(divisionId);
    }

    doesNotHaveDivisionAccess(divisionId) {
        return !this.hasDivisionAccess(divisionId);
    }

    hasCustomPermission(permission) {
        return this.custom_permissions.hasOwnProperty(permission) && this.custom_permissions[permission];
    }

    isProgrammer() {
        return [Role.PROGRAMMER].includes(this.role_id);
    }

    isManager() {
        return [Role.MANAGER].includes(this.role_id);
    }

    isAnalyst() {
        return [Role.ANALYST].includes(this.role_id);
    }

    isDirector() {
        return [Role.DIRECTOR].includes(this.role_id);
    }

    isBdcDirector() {
        return [Role.DIRECTOR_BDC].includes(this.role_id);
    }

    isCommercialDirector() {
        return [Role.COMMERCIAL].includes(this.role_id);
    }

    isBdc() {
        return [Role.AGENT].includes(this.role_id);
    }

    isAdvisor() {
        return [Role.ADVISOR].includes(this.role_id);
    }

    isServiceAdvisor() {
        return [Role.SERVICE_ADVISOR].includes(this.role_id);
    }

    isServiceAgent() {
        return [Role.SERVICE_AGENT].includes(this.role_id);
    }

    isServiceDirector() {
        return [Role.DIRECTOR].includes(this.role_id) && this.hasDivision(Division.SERVICE);
    }

    isService() {
        return this.isServiceAdvisor() || this.isServiceAgent();
    }

    isClient() {
        return this.role_id >= 4 && this.role_id != 6;
    }

    isAdmin() {
        return Role.hasAdminRights(this.role_id);
    }

    hasAdvisorSkills() {
        return Role.hasAdvisorSkills(this.role_id);
    }

    isSuperBdc() {
        return this.isBdc() && this.bdc_super_access;
    }

    isSuperService() {
        return this.isService() && this.service_super_access;
    }

    hasPhoneUp() {
        return this.account.phone_up && !empty(this.module_access.phone_up) && this.module_access.phone_up;
    }

    hasWebBoost() {
        return this.account.webboost && !empty(this.module_access.web_boost) && this.module_access.web_boost;
    }

    hasWalkIn() {
        return this.account.walk_in && !empty(this.module_access.walk_in) && this.module_access.walk_in;
    }

    hasLeadXpress() {
        return this.account.leadxpress && !empty(this.module_access.leadxpress) && this.module_access.leadxpress;
    }

    hasWebOrder() {
        return this.account.web_order && !empty(this.module_access.web_order) && this.module_access.web_order;
    }

    hasLoyalty() {
        return this.account.loyalty && !empty(this.module_access.loyalty) && this.module_access.loyalty;
    }

    hasCommercial() {
        return this.account.commercial && !empty(this.module_access.commercial) && this.module_access.commercial;
    }

    hasRenewal() {
        return this.account.renewal && !empty(this.module_access.renewal) && this.module_access.renewal;
    }

    hasEvent() {
        return this.account.event && !empty(this.module_access.event) && this.module_access.event;
    }

    hasService() {
        return this.account.service && !empty(this.module_access.service) && this.module_access.service;
    }

    hasSaleTable() {
        return this.account.sale_table && !empty(this.module_access.sale_table) && this.module_access.sale_table;
    }

    hasNioText() {
        return this.niotext;
    }

    childrenHasNioText() {
        return this.children.some(child => child.hasNioText());
    }

    hasSimpleAccess() {
        return this.isAdvisor() || (this.isService() && !this.isSuperService()) || (this.isBdc() && !this.isSuperBdc());
    }

    isAuthorizedToActionOnLead(lead) {
        if (
            !this.account.assigned_lead &&
            ((!lead.user_id && this.isAdvisor()) || (!lead.bdc_user_id && this.isBdc() && !this.bdc_super_access))
        ) {
            return false;
        }

        return true;
    }

    isParentOf(userId) {
        return this.children.some(child => child.id == userId);
    }

    isSameUser(userId) {
        if (!userId) {
            return false;
        }

        if ([this.id, this.parent_user_id].includes(userId)) {
            return true;
        }

        return this.isParentOf(userId);
    }

    hasUserAccess(userId) {
        if (this.isAdmin() || this.id == userId) {
            return true;
        }

        return this.isParentOf(userId);
    }

    canOnlySeeAccount(accountId) {
        return this.hasAccessToSeeAccount(accountId) && !self.hasAccessToEditAccount(accountId);
    }

    hasAccessToSeeAccount(accountId) {
        if (!this.id || !accountId) {
            return false;
        }

        if (accountId == Account.ACTIVIX_RECRUTEMENT && !this.sensitive_access && this.account_id != accountId) {
            return false;
        }

        if (this.isAdmin() || this.account_id == accountId) {
            return true;
        }

        const hasChildForAccount = this.children.some(user => {
            return user.account_id == accountId;
        });

        if (hasChildForAccount) {
            return true;
        }

        if (this.isDirector()) {
            return this.groups.some(group => {
                return group.accounts.some(account => account.id == accountId);
            });
        }

        return false;
    }

    hasAccessToEditAccount(accountId) {
        if (!this.id || !accountId) {
            return false;
        }

        if (this.isAdmin() || this.account_id == accountId) {
            return true;
        }

        const hasChildForAccount = this.children.some(user => {
            return user.account_id == accountId;
        });

        if (hasChildForAccount) {
            return true;
        }

        return false;
    }

    hasAccessToExport() {
        return (this.isAdmin() || this.allow_export_access) && can('export.generate_report:create');
    }

    hasAccessToEquity() {
        return this.isAdmin() && can('accounts.renewal_alert_options:update');
    }

    hasAccessTo(page = '', accountId = null) {
        return this.checkAccessTo(page, accountId) || this.children.some(child => child.checkAccessTo(page));
    }

    checkAccessTo(page, accountId = null) {
        const dashboardSlug = snakeCase(page.replace('dashboards.', ''));

        // If user only has access to service division, hide all dashboards but service or events
        if (this.divisions.length == 1 && this.hasDivision(Division.SERVICE) && page != 'dashboards.leadXpress') {
            if (page.startsWith('dashboards.')) {
                return (
                    ['dashboards.service', 'dashboards.event'].includes(page) &&
                    this.account[dashboardSlug] &&
                    this.module_access[dashboardSlug]
                );
            }

            if (page == 'inTurn') {
                return false;
            }
        }

        if (accountId && !this.hasAccessToSeeAccount(accountId)) {
            return false;
        }

        // General for all users' role
        switch (page) {
            case 'nioText':
                return this.hasNioText() && this.account.hasNioText();

            case 'dashboards.allLead':
            case 'customer.update':
            case 'leads.update':
            case 'users.update':
            case 'schedule':
            case 'search':
            case 'suppliers.accountNotFound':
                return true;
        }

        if (this.isAdmin()) {
            return true;
        }

        switch (page) {
            case 'duplicates':
                return this.account.duplicates;

            case 'imports.index':
            case 'imports.match':
                return this.allow_import_access;

            case 'automations.index':
            case 'automations.store':
            case 'automations.update':
                return this.account.automation && this.automation_access;

            case 'accounts.update':
                return this.isDirector();

            case 'flow.webBoost':
                return this.hasWebBoost() && (this.isDirector() || this.isBdcDirector());

            case 'flow.phoneUp':
                return this.hasPhoneUp() && !this.account.haveCustomPhoneProvider() && (this.isDirector() || this.isBdcDirector());

            case 'flow.leadXpress':
                return this.hasLeadXpress() && (this.isDirector() || this.isBdcDirector());

            case 'flow':
            case 'users.index':
            case 'users.store':
                return this.isDirector() || this.isBdcDirector();

            case 'dashboards.commercial':
            case 'dashboards.event':
            case 'dashboards.loyalty':
            case 'dashboards.renewal':
            case 'dashboards.service':
                return this.account[dashboardSlug] && this.module_access[dashboardSlug];
            case 'dashboards.inventory':
                return this.hasAccessToInventorySupplier();
            case 'dashboards.leadXpress':
            case 'dashboards.phoneUp':
            case 'dashboards.saleTable':
            case 'dashboards.walkIn':
            case 'dashboards.webBoost':
            case 'dashboards.webOrder':
                return (
                    (this.account[dashboardSlug] || this.account[dashboardSlug.replace('_', '')]) &&
                    (this.module_access[dashboardSlug] || this.module_access[dashboardSlug.replace('_', '')])
                );

            case 'objectives.saleTable':
                return this.isDirector() && this.module_access.sale_table && this.account.sale_table;

            case 'objectives.activityReport':
                return (
                    (this.isDirector() || this.isBdcDirector()) &&
                    this.module_access.activity_report &&
                    this.account.activity_report
                );

            case 'dashboards.activityReport':
                return this.module_access.activity_report && this.account.activity_report;

            case 'inTurn.show':
            case 'inTurn.management':
                return this.account.in_turn || this.in_turn_edit;

            case 'templates.index':
            case 'templates.store':
            case 'templates.update':
            case 'inventory':
            case 'calendar':
                return true;
            case 'leads.trash':
                return this.isAdmin() || (Role.hasDirectorRights(this.role_id) && this.custom_permissions.delete_lead);
            case 'analytics':
                return this.isAdmin() || this.has_analytics_access && this.analytics_dashboards.length;
            case 'campaign':
                return this.campaign;
        }

        return false;
    }

    hasAccessToInventorySupplier() {
        return this.account.hasInventorySupplier();
    }

    hasAccessToService() {
        return this.hasAccessTo('dashboards.service') || this.hasDivisionAccess(Division.SERVICE);
    }

    hasProfilePicture() {
        return this.profile_picture != '/img/nobody.jpg';
    }

    static selectOptions(users) {
        return users
            .map(user => {
                return {
                    id: user.id,
                    label: user.fullName,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }
}
